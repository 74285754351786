import React, { Component } from "react";
import { addSlug } from "../../store/slug/actions";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  CardBody,
  Card,
} from "reactstrap";
const categoryOption = [
  { label: "Grooming", value: "Grooming" },
  { label: "Fashion", value: "Fashion" },
  
];
const displayFormat = [
  { label: "Small Slider", value: "Small Slider" },
  { label: "Carousel", value: "Carousel" },
];
class AddSlug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  reset = () => {
    this.setState({
      data: {},
    });
  };
  render() {
    return (
      <React.Fragment>
        <Container fluid={true} className="mt-2 bg-white">
          <Row className="content">
            <Col xl="12" className="p-2 mt-2">
              <Card>
                <section>
                  <h4 className="text-center mt-2">Add Product Slug</h4>
                </section>
                <CardBody style={{ padding: "5%" }}>
                  <Formik
                    onSubmit={(values) => {
                      console.log("submitvalue", values);
                      this.setState({data:values}, () => {
                        this.props.addSlug(this.state.data,(res)=>{
                          if (res) {
                            NotificationManager.success("Added Successfully", "Success");
                            this.props.history.push("/viewSlug");
                          } else {
                            NotificationManager.error(res["message"], "Error");
                          }
                        })
                      });
                    }}
                    initialValues={{
                      slugName: "",
                      category: "",
                      sequence: "",
                      displayFormat: "",
                    }}
                    validationSchema={yup.object().shape({
                      sequence: yup
                        .number()
                        .required("Sequence is required"),
                        // .min(1, "Too Short!")
                        // .max(10, "Too Long!"),
                      category: yup.string().required("Category is required"),
                      displayFormat: yup
                        .string()
                        .required("Display Format is Required"),
                      slugName: yup.string().required(" Slug Name is required"),
                    })}
                  >
                    {({
                      handleSubmit,
                      dirty,
                      isSubmitting,
                      handleReset,
                      handleChange,
                      handleBlur,
                      values,

                      touched,
                      isValid,
                      errors,

                      setFieldValue,
                      setFieldTouched,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mt-2">
                          <Col md="12">
                            <FormGroup>
                              <Label
                                htmlFor="example-text-input"
                                className="fontRoboto127 mb-2"
                              >
                                Slug Name
                              </Label>
                              <Input
                                name="slugName"
                                placeholder="SlugName"
                                value={values.slugName}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="slugName"
                                className={
                                  errors.slugName && touched.slugName
                                    ? "input-error form-control-lg"
                                    : "form-control-lg"
                                }
                              />
                              <ErrorMessage
                                name="slugName"
                                component="span"
                                className="error"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md="12">
                            <FormGroup>
                              <Label
                                htmlFor="example-text-input"
                                className="fontRoboto127 mb-2"
                              >
                                Category
                              </Label>
                              <Select
                                defaultOptions
                                name="category"
                                selected={values.category}
                                options={categoryOption}
                                onChange={(e) => {
                                  console.log(e.label);
                                  setFieldValue("category", e.label);
                                }}
                                onBlur={handleBlur}
                                classNamePrefix="select2-selection"
                                className={
                                  errors.category && touched.category
                                    ? "input-error"
                                    : null
                                }
                              />
                              <ErrorMessage
                                name="category"
                                component="span"
                                className="error"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col md="12">
                            <FormGroup>
                              <Label
                                htmlFor="example-text-input"
                                className="fontRoboto127 mb-2"
                              >
                                Display Format
                              </Label>

                              <Select
                                defaultOptions
                                name="displayFormat"
                                selected={values.displayFormat}
                                options={displayFormat}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  console.log(e.label);
                                  setFieldValue("displayFormat", e.label);
                                }}
                                classNamePrefix="select2-selection"
                                className={
                                  errors.displayFormat && touched.displayFormat
                                    ? "input-error"
                                    : null
                                }
                              />
                              <ErrorMessage
                                name="displayFormat"
                                component="span"
                                className="error"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col md="12">
                            <FormGroup>
                              <Label
                                htmlFor="example-text-input"
                                className="fontRoboto127 mb-2"
                              >
                                Sequence
                              </Label>
                              <Input
                                name="sequence"
                                placeholder="Sequence "
                                type="number"
                                value={values.sequence}
                                id="sequence"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={
                                  errors.sequence && touched.sequence
                                    ? "input-error"
                                    : null
                                }
                              />
                              <ErrorMessage
                                name="sequence"
                                component="span"
                                className="error"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Button
                          color="primary"
                          type="submit"
                          className="mt-4 mr-3"
                        >
                          ADD
                        </Button>

                        <Button
                          color="success"
                          type="button"
                          className="mt-4"
                          onClick={handleReset}
                        >
                          RESET
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(null, { addSlug })(AddSlug);
