import React, { Component } from 'react'
import { MDBDataTable } from "mdbreact";
var data = {};
export default class BlogSlugTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
      }
      render() {
        let rows = this.props?.rows;
        data = {
            columns: [
                {
                  label: "Slugs",
                  field: "slugName",
                  sort: "asc",
                  width: 200,
                },
        
                {
                  label: "Category",
                  field: "category",
                  sort: "asc",
                  width: 200,
                },
        
                {
                  label: "Display Format",
                  field: "displayFormat",
                  sort: "asc",
                  width: 200,
                },
        
                {
                  label: "Brand Slug",
                  field: "brandSlug",
                  sort: "asc",
                  width: 200,
                },
        
                {
                  label: "Sequence",
                  field: "sequence",
                  sort: "asc",
                  width: 200,
                },
                
                {
                  label: "Status",
                  field: "state",
                  sort: "asc",
                  width: 200,
                },
        
                {
                  label: "Actions",
                  field: "actions",
                  sort: "asc",
                  width: 200,
                },
              ],
          rows: rows,
        };
        return (
          <div className="order mt-4">
            <MDBDataTable
              responsive
              sortable
              data={data}
              paging={false}
              className="hover text-center"
              noBottomColumns
              searching={false}
            />
          </div>
        );
      }
    }
    

