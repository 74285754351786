import { Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import VerticalMenuBar from "../components/VerticalMenuBar";
import { changeSwitchTheme } from "../store/layout/actions";
import HeaderNew from "../components/HorizontalLayout/HeaderNew";

const AppRoute = ({
  component: Component,
  layout: Layout,
  hasAccess,
  isAuthProtected,
  ...rest
}) => {
  const [mini, setMini] = useState(true);

  const menuBarIn = () => {
    if (mini === true) {
      document.getElementById("contentCard").style.marginLeft = "225px";
      setMini(false);
    }
  };
  const menuBarOut = () => {
    if (mini === false) {
      document.getElementById("contentCard").style.marginLeft = "82px";
      setMini(true);
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        // let token = localStorage.getItem("authUser");
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } else {
          return (
            <Layout>
              { props.location.pathname !== "/login"? (
                <Grid container>
                  <Grid item xs={1}>
                    <VerticalMenuBar
                      menuBarIn={menuBarIn}
                      menuBarOut={menuBarOut}
                      mini={mini}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={11} lg={12} id="contentCard">
                    <HeaderNew {...props} />
                    <Component {...props} {...rest} />
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <Component {...props} {...rest} />
                  </Grid>
                </Grid>
              )}
            </Layout>
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
 
  return {
    ...state,
    switchsTheme: state.Layout?.switchTheme,
  };
};
export default connect(mapStateToProps, { changeSwitchTheme })(AppRoute);
