// import { mapKeys } from 'lodash';
import { types } from "../actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_COUPON_SUCCESS:
      return { couponList: action.payload };

    //update 
    case types.UPDATE_COUPON_SUCCESS:
      return { updatedCoupon: action.payload };

    //update slug
    case types.GET_BRAND_LIST_SUCCESS:
      return { brandListByCat: action.payload };

    case types.DELETE_COUPON_SUCCESS:
      return { deleteCoupon: action.payload };

    case types.CREATE_COUPON_SUCCESS:
      return { createdCoupon: action.payload };
    default:
      state = { ...state };
      break;
  }

  return state;
};
