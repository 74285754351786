import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import { MoonLoader } from "react-spinners";
import { fetchSlugList, deleteSlugList } from "../../store/slug/actions";
import { connect } from "react-redux";
import SlugTable from "./SlugTable";
import EditSlugModel from "./EditSlugModel";
import { Grid } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import Pagination from "../Common/PaginateData";
import { Link } from "react-router-dom";
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 1;

class Slug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editSlugModel: false,
      editByIdData: "",
      slug: [],
      rows: [],
    };
  }

  componentDidMount = () => {
    this.getAllData();
  };

  getAllData = () => {
    this.setState({ loading: true });
    this.props.fetchSlugList((offset-1), (res) => {
      let rowpro = [];
      if (Array.isArray(res?.response?.body?.productApiResponseList?.content)) {
        let slugs = res?.response?.body?.productApiResponseList?.content;
        totCnt = res?.response?.body?.count;
        fromVal = totCnt !== 0 ? (offset - 1) * 10 + 1 : fromVal;
        toVal = (offset - 1) * 10 + slugs?.length;
        totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
        slugs &&
          slugs.forEach((element, index, array) => {
            if (element) {
              rowpro.push({
                slugName: element?.slugName,
                category: element?.category,
                displayFormat: element.displayFormat
                  ? element.displayFormat
                  : "N/A",
                brandSlug: element?.brandSlug === true ? "true" : "false",
                sequence: element["sequence"] ? element["sequence"] : "N/A",
                state:
                  element["state"] === null ? (
                    <div className="badge badge-soft-success font-size-14">
                     Active
                  </div>
                  ) : (
                    <div className="badge badge-soft-success font-size-14">
                      {element?.state}
                    </div>
                  ),
                actions: (
                  <div className="d-flex align-items-center tableBtns ">
                    <Button
                      outline
                      color="secondary"
                      size="md"
                      onClick={() => {
                        this.slugModel(element, "edit");
                      }}
                    >
                      Edit
                    </Button>{" "}
                    &nbsp;&nbsp;
                    <Button
                      outline
                      color="secondary"
                      size="md"
                      onClick={() => {
                        this.deleteSlug(element?.slugId);
                      }}
                    >
                      Delete
                    </Button>{" "}
                    &nbsp;&nbsp;
                  </div>
                ),
              });
            }
          });
        this.setState({ rows: rowpro, loading: false });
      } else {
        this.setState({ loading: false }, () => {
          NotificationManager.error(res?.error, "Error");
        });
      }
      this.setState({ loading: false });
    });
  };
  slugModel = (data) => {
    this.setState({ editSlugModel: !this.state.editSlugModel }, () => {
      this.setState({ editByIdData: data });
    });
  };
  deleteSlug = (id) => {
    // window.alert("Are you sure to delete slug!");
    this.props.deleteSlugList(id, (res) => {
      if (res) {
        NotificationManager.success("Deleted Successfully", "Success");
        this.getAllData();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 1;
  };

  resetSlug = () => {
    offset = 1;
    this.getAllData();
  };

  handleChangePage = (event, val) => {
    this.setState({ loading: true });

    offset = val;
    this.getAllData();
  };

  render() {
    const { loading, rows } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="mt-1">
          <Container fluid>
            <Row>
              <Col xs={12} className="p-1">
                <Card>
                  <CardBody>
                    <Grid
                      className="breadcrumb p-0"
                      container
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={6} md={8}>
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <i className="fas fa-book-open"></i>  Product Slug
                          </li>
                          <li className="breadcrumb-item active">SlugList</li>
                        </ol>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <div className="d-flex justify-content-center flex-fill">
                          <div className="p-2">
                            <Link to="/addSLug">
                              <Button size="sm" color="primary">
                                Add Slug
                              </Button>
                            </Link>
                          </div>
                          <div className="p-2">
                            <Button
                              size="sm"
                              color="success"
                              onClick={() => {
                                this.resetSlug();
                              }}
                            >
                              RESET
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <SlugTable rows={rows ? rows : []} />
                    <Pagination
                      fromVal={fromVal}
                      toVal={toVal}
                      totCnt={totCnt}
                      offset={offset}
                      totPages={totPages}
                      handleChangePage={this.handleChangePage}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <EditSlugModel
          isOpen={this.state.editSlugModel}
          toggleModel={this.slugModel}
          rowsData={this.state.editByIdData}
          getAllData={this.getAllData}
        />
      </React.Fragment>
    );
  }
}
export default connect(null, {
  fetchSlugList,
  deleteSlugList,
})(Slug);
