// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  postLogin
} from "../../helpers/auth_helper";


function* workauthLogin({ payload: { data, history }, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/login?username=${data.username}&password=${data?.password}`;
    const response = yield call(postLogin, uri);
    //   Dispatch the action to the reducers
    yield put({
      type: types.AUTH_LOGIN_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }  
  }
function* worklogoutUser({ payload: { history } }) {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("authUser");
      localStorage.removeItem("refreshToken");
      window.location = "/login";
      yield put({
        type: types.LOGOUT_USER_SUCCESS,
      });
    } catch (error) {
       console.log(error)
    }
}

/**
 * Watchers
 */

export function* watchAuthLogin() {
  yield takeEvery(types.AUTH_LOGIN, workauthLogin);
}

export function* watchAuthLogout() {
  yield takeEvery(types.LOGOUT_USER, worklogoutUser);
}

function* AuthSaga() {
  yield all([
    fork(watchAuthLogin),
    fork(watchAuthLogout),
  ]);
}

export default AuthSaga;
