// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  deleteApi,
  putApiAuth,
  // getApiNoToken,
  postApiNoToken,
  postApi
  // putApi,
} from "../../helpers/auth_helper";

function* workfetchCoupon({ values, callback }) {
  console.log("🚀 ~ file: saga.js ~ line 13 ~ function*workfetchCoupon ~ values", values)
  
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/getCoupon`;
    // const uri = process.env.REACT_APP_SERVERURL + `/vehicleType/update/${Id}`;
    const response = yield call(postApiNoToken, uri, values);
    //   Dispatch the action to the reducers
    // callback(response);
    yield put({
      type: types.FETCH_COUPON_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workupdateCoupon({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/updateCoupon`;
    // const uri = process.env.REACT_APP_SERVERURL + `/vehicleType/update/${Id}`;
    const response = yield call(putApiAuth, uri, values);
    console.log("🚀 ~ file: saga.js ~ line 40 ~ function*workupdateCoupon ~ response", response)
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPDATE_COUPON_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}
function* workcreateCoupon({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/createCoupon`;
    // const uri = process.env.REACT_APP_SERVERURL + `/vehicleType/update/${Id}`;
    const response = yield call(postApi, uri, values);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_COUPON_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workdeleteCoupon({ id, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/deleteCouponById/${id}`;
    const response = yield call(deleteApi, uri, id);
    yield put({
      type: types.DELETE_COUPON_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workBrandListByCat({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/getBrands`;
    const response = yield call(postApiNoToken, uri, values);
    //   Dispatch the action to the reducers
    yield put({
      type: types.GET_BRAND_LIST_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}
/**
 * Watchers
 */

export function* watchFetchCoupon() {
  yield takeEvery(types.FETCH_COUPON, workfetchCoupon);
}

export function* watchUpdateCoupon() {
  yield takeEvery(types.UPDATE_COUPON, workupdateCoupon);
}

export function* watchCreateCoupon() {
  yield takeEvery(types.CREATE_COUPON, workcreateCoupon);
}

export function* watchDeleteCoupon() {
  yield takeEvery(types.DELETE_COUPON, workdeleteCoupon);
}

export function* watchBrandListByCat() {
  yield takeEvery(types.GET_BRAND_LIST, workBrandListByCat);
}

function* CouponSaga() {
  yield all([
    fork(watchFetchCoupon),
    fork(watchUpdateCoupon),
    fork(watchCreateCoupon),
    fork(watchDeleteCoupon),
    fork(watchBrandListByCat),
  ]);
}

export default CouponSaga;
