import { types } from "../actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_BLOG_SUCCESS:
      return { blogList: action.payload };

    case types.FETCH_TAG_SUCCESS:
      return { tagList: action.payload };

    case types.UPDATE_BLOG_SUCCESS:
      return { updatedBlog: action.payload };

    case types.DELETE_BLOG_SUCCESS:
    return { deleteBlog: action.payload };

    case types.CREATE_BLOG_SUCCESS:
      return { createBlog: action.payload };

    case types.GET_BLOG_CATEGORIES_SUCCESS:
      return { blogCategory: action.payload };

    case types.UPLOAD_BLOG_IMAGE_SUCCESS:
      return { blogImage: action.payload };
    //blog slug

    case types.FETCH_BLOGSLUG_LIST_SUCCESS:
      return { blogSlugList: action.payload };

      case types.ADD_BLOGSLUG_LIST_SUCCESS:
        return { addblogSlugList: action.payload };

    case types.EDIT_BLOGSLUG_LIST_SUCCESS:
      return { editBlogSlugList: action.payload };

    case types.DELETE_BLOGSLUG_LIST_SUCCESS:
      return { deleteBlogSlugList: action.payload };

    default:
      state = { ...state };
      break;
  }

  return state;
};
