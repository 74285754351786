import { combineReducers } from "redux";
import Layout from "./layout/reducer";
import Product from "./products/reducer/index";
import Slug from "./slug/reducer/index";
import Blog from "./blogs/reducer/index"
import Login from "./auth/reducer/index";
import Coupon from "./coupon/reducer/index";

const rootReducer = combineReducers({
  Layout,
  Product,
  Slug,
  Login,
  Blog,
  Coupon
});

export default rootReducer;
