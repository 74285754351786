import { types } from "./actionTypes";


export const adminLogin = (data,history, callback ) => ({
  type: types.AUTH_LOGIN,
  payload: { data, history },
  callback
});


export const logoutUserSuccess = () => {
  return {
    type: types.LOGOUT_USER,
    payload: {},
  };
};

