import { all } from "redux-saga/effects";

import ProductsSaga from "./products/saga";
import BlogSaga from "./blogs/saga";
import SlugSaga from "./slug/saga"; 
import AuthSaga from "./auth/saga"
import CouponSaga from "./coupon/saga"

export default function* rootSaga() {
  yield all([
   
    ProductsSaga(),
    SlugSaga(),
    AuthSaga(),
    BlogSaga(),
    CouponSaga()
   
  ]);
}
