// @flow PRODUCT SLUG
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  deleteApi,
  // getApi,
  getApiNoToken,
  postApiNoToken,
  // patchApi,
  // postApi,
  // uploadExcel
} from "../../helpers/auth_helper";


function* workfetchSlug({ category, callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL + `/productSlugList/${category}`;
    const response = yield call(getApiNoToken, uri);
    callback(response);
    yield put({
      type: types.FETCH_SLUG_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch dealer.", error);
  }
}

function* workfetchSlugList({ offset, callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL + `/productSlugLists?page=${offset}&size=10`;
    const response = yield call(getApiNoToken, uri);
    callback(response);
    yield put({
      type: types.FETCH_SLUG_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch dealer.", error);
  }
}


function* workupdateSlugByProduct({ Id, values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/slug/updateSlug/${Id}`;
    // const uri = process.env.REACT_APP_SERVERURL + `/vehicleType/update/${Id}`;
    const response = yield call(postApiNoToken, uri, values);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPDATE_SLUG_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}
function* workcreateSlug({  values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/createProductSlug`;
    // const uri = process.env.REACT_APP_SERVERURL + `/vehicleType/update/${Id}`;
    const response = yield call(postApiNoToken, uri, values);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_SLUG_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workeditSlugList({ values , callback}) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/updateProductSlug`;
    const response = yield call(postApiNoToken,uri,values)
    yield put ({
      type:types.EDIT_SLUG_LIST_SUCCESS,
      payload: response
    })
    callback(response)
  } catch (error) {
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workDeleteSlugList({id , callback}) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/deleteProductSlug/${id}`;
    const response = yield call(deleteApi,uri)
    yield put ({
      type:types.DELETE_SLUG_LIST_SUCCESS,
      payload: response
    })
    callback(response)
  } catch (error) {
    console.log("Request failed! Could not fetch .", error);
  }
}
/**
 * Watchers
 */

export function* watchFetchSlug() {
  yield takeEvery(types.FETCH_SLUG, workfetchSlug);
}
export function* watchFetchSlugList() {
  yield takeEvery(types.FETCH_SLUG_LIST, workfetchSlugList);
}

export function* watchUpdateSlugByProduct() {
  yield takeEvery(types.UPDATE_SLUG, workupdateSlugByProduct);
}

export function* watchCreateSlug() {
  yield takeEvery(types.CREATE_SLUG, workcreateSlug);
}

export function* watchEditSlugList() {
  yield takeEvery(types.EDIT_SLUG_LIST, workeditSlugList);
}

export function* watchDeleteSlugList() {
  yield takeEvery(types.DELETE_SLUG_LIST, workDeleteSlugList);
}

function* SlugSaga() {
  yield all([
    fork(watchFetchSlug),
    fork(watchFetchSlugList),
    fork(watchUpdateSlugByProduct),
    fork(watchCreateSlug),
    fork(watchEditSlugList),
    fork(watchDeleteSlugList),
  ]);
}

export default SlugSaga;
