export const types = {
  FETCH_SLUG: "FETCH_SLUG",
  FETCH_SLUG_SUCCESS: "FETCH_SLUG_SUCCESS",

  UPDATE_SLUG: "UPDATE_SLUG",
  UPDATE_SLUG_SUCCESS: "UPDATE_SLUG_SUCCESS",

  CREATE_SLUG: "CREATE_SLUG",
  CREATE_SLUG_SUCCESS: "CREATE_SLUG_SUCCESS",

  FETCH_SLUG_LIST: "FETCH_SLUG_LIST",
  FETCH_SLUG_LIST_SUCCESS: "FETCH_SLUG_LIST_SUCCESS",

  EDIT_SLUG_LIST: "EDIT_SLUG_LIST",
  EDIT_SLUG_LIST_SUCCESS: "EDIT_SLUG_LIST_SUCCESS",

  DELETE_SLUG_LIST: "DELETE_SLUG_LIST",
  DELETE_SLUG_LIST_SUCCESS: "DELETE_SLUG_LIST_SUCCESS",
};
