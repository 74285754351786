import React, { Component } from "react";
import { updateSlugList} from "../../store/slug/actions";
import { NotificationManager } from "react-notifications";
import { MDBModal, MDBModalBody, MDBContainer } from "mdbreact";
import Select from "react-select";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
} from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
 class EditSlugModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slugId:"",
     slugName:"",
     slugState:"",
     brandSlug:"",
     category:"",
     sequence:"",
     displayFormat:""
    };
  }

  componentDidUpdate = (prevProps)=>{
  
    if (prevProps.rowsData !== this.props?.rowsData) {
      let slugsList = this.props?.rowsData;
      let category = {
        label: slugsList?.category? slugsList?.category:"",
        value:slugsList?.category? slugsList?.category:""
      }
      let displayFormat = {
        label: slugsList?.displayFormat? slugsList?.displayFormat:"",
        value:slugsList?.displayFormat? slugsList?.displayFormat:""
      }
      this.setState({
        slugId:slugsList?.slugId,
        slugName: slugsList?.slugName,
        slugState: slugsList?.slugState,
        brandSlug: slugsList?.brandSlug,
        category: category,
        sequence: slugsList?.sequence,
        displayFormat: displayFormat,
      });
    }
  
  }
  handleSubmit = () => {
    const {
      slugId,
      slugName,
      brandSlug,
      category,
      slugState,
      sequence,
      displayFormat,
    } = this.state;
    let formObj = {
      id:       slugId ? slugId : "",
      slugName: slugName ? slugName : "",
      brandSlug: brandSlug ? brandSlug : "",
      category: category ? category?.label : "",
      slugState: slugState ? slugState : "Active",
      sequence: sequence ? sequence : "",
      displayFormat: displayFormat ? displayFormat?.label : "",
    };
    this.props.updateSlugList(formObj, (res) => {
      if (res) {
        NotificationManager.success("Updated Successfully", "Success");
        this.props.toggleModel();
        this.props.getAllData();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  onChangeInput = (name, val) => {
    this.setState({
      [name]: val,
    });
  };

  selectBlogCatGroup =(cat)=>{
    this.setState({category:cat})
  }

  selectDisplayGroup =(display)=>{
    this.setState({displayFormat:display})
  }

  render() {
    const {category} = this.state
    const modalStyle = {
      height: "100%",
      maxHeight:"100%",
      width: "100%",
      backgroundColor: "#ffffff",
      paddingTop: "2px",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "auto",
      position: "relative",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      color: "#2800fc",
      width: "20px",
      marginRight: "0px",
    };

    const categoryOption = [
      { label: "Grooming", value: "Grooming" },
      { label: "Fashion", value: "Fashion" },
    ];
    const displayFormat = [
      { label: "Small Slider", value: "Small Slider" },
      { label: "Carousel", value: "Carousel" },
    ];
    return (
      <React.Fragment>
        <MDBContainer className="text-capitalize viewModel">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggleModel()}
            size="lg"
            backdrop={true}
          >
            <MDBModalBody>
              <Card style={modalStyle}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={headerStyle}>Edit Slug</span>

                  <CloseIcon
                    style={closeStyle}
                    onClick={() => this.props.toggleModel()}
                  />
                </div>
                <div>
                  <Form className="p-4">
                    <Row className="mt-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Slug Name
                          </Label>
                          <Input
                            name="slugName"
                            placeholder="SlugName"
                            type="text"
                            value={this.state?.slugName}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("slugName", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="12">
                      <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Category
                          </Label>
                          <Select
                            className="form-control-xs"
                            value={category}
                            onChange={this.selectBlogCatGroup}
                            options={categoryOption}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Display Format
                          </Label>
                        
                          <Select
                            className="form-control-xs"
                            onChange={this.selectDisplayGroup}
                            value={this.state.displayFormat}
                            options={displayFormat}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Brand Slug
                          </Label>
                          <Input
                            name="brandSlug"
                            placeholder="Brand Slug"
                            type="textarea"
                            rows="1"
                            value={this.state.brandSlug}
                            className="form-control-lg"
                            id="validationCustom01"
                            onChange={(e) =>
                              this.onChangeInput("brandSlug", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row className="mt-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Slug State
                          </Label>
                          <Input
                            name="slugState"
                            placeholder="Status ex:Active or Deactive"
                            type="text"
                            value={this.state.slugState}
                            className="form-control-lg"
                            id="validationCustom04"
                            onChange={(e) =>
                              this.onChangeInput("slugState", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <Row className="mt-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Sequence
                          </Label>
                          <Input
                            name="sequence"
                            placeholder="Sequence "
                            type="text"
                            value={this.state.sequence}
                            className="form-control-lg"
                            id="validationCustom04"
                            onChange={(e) =>
                              this.onChangeInput("sequence", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      color="primary"
                      // type="submit"
                      className="mt-4"
                      onClick={this.handleSubmit}
                    >
                      UPDATE
                    </Button>
                  </Form>
                </div>
              </Card>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}
export default connect(null,{updateSlugList})(EditSlugModel)
