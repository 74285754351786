import React, { Component } from "react";

import { Row, Col, Container, FormGroup,Button} from "reactstrap";
import { adminLogin } from "../../store/auth/actions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import background from "../../assets/images/layouts/signup_bg.jpg";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
const logo = require("../../assets/images/" + process.env.REACT_APP_LOGO_IMAGE);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      userType: "Admin",
    };
  }
  valueStore = (type, e) => {
    let emailCheck = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
    if (type === "username") {
      if (e?.target?.value.match(emailCheck)) {
        this.setState({ email: e?.target?.value });
      }
    }
    let obj = {};
    obj[type] = e?.target?.value;
    this.setState({ ...obj });
  };
  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.username !== "" && this.state.password !== "") {
        this.loginAction();
      }
    }
  };

  loginAction = () => {
    let data = {};
    if (this.state.username) {
      data = { username: this.state.username, password: this.state.password };
    }
    if (this.state.username !== "" && this.state.password !== "") {
      this.props.adminLogin(data, this.props.history, (res) => {
        if (res.message === "Success" || res.code === 200) {
          NotificationManager.success("User Logged In", "LogIn Successful");
          localStorage.setItem("authUser", JSON.stringify(res?.data.email));
          localStorage.setItem("token", JSON.stringify(res?.data?.jwtToken));
          localStorage.setItem("refreshToken", res?.data?.refreshToken);
   
          // this.props?.history?.push("/");
          window.location.href = "/";
        } else {
          NotificationManager.error(res.message, "Error");
        }
      });
    }
  
  };

  componentDidMount() {
    const isLogin = localStorage.getItem("authUser");
    if (isLogin) this.props.history.push("/product");
    document.body.classList.add("auth-body-bg");
  }

  handleRemember = () => {};

  componentWillUnmount() {
    document.body.classList.add("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <Row
            className="justify-content-md-center loginDivCss"
            style={{ backgroundImage: `url(${background})` }}
          >
            <Col
              xxl={4}
              xl={4}
              md={6}
              lg={4}
              sm={8}
              xs={9}
              className="loginLogRight"
            >
              <div className="mt-2">
                <img
                  src={logo}
                  alt="darkLogoImage"
                  width="150px"
                  height="74px"
                />
                <hr />
                <h2>Welcome</h2>
                <p>Please Sign in</p>
                <div>
                  <AvForm className="form-horizontal"
                  onValidSubmit={this.loginAction}
                               
                  >
                    <FormGroup>
                      <AvField
                        name="username"
                        placeholder="Username"
                        type="text"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "This is a required field."
                          },
                          email: {
                            value: true,
                            errorMessage: "Please enter a valid email address"
                          }
                        }}
                        onKeyPress={this.handleKeyDown}
                        onChange={(e) => {
                          this.valueStore("username", e);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <AvField
                        id="exampleNumber"
                        name="password"
                        placeholder="Password"
                        type="password"
                        validate={{required: true,}}
                        errorMessage="This field cannot be empty"
                        onChange={(e) => {
                          this.valueStore("password", e);
                        }}
                        onKeyPress={this.handleKeyDown}
                      />
                    </FormGroup>
                    <FormGroup>
                      <AvField
                        name="userType"
                        placeholder="userType"
                        type="select"
                        onKeyPress={this.handleKeyDown}
                        onChange={(e) => {
                          this.valueStore("userType", e);
                        }}
                      >
                        <option value="admin">Admin</option>
                      </AvField>
                    </FormGroup>
                    <div className="d-flex align-items-center justify-content-between checkDivCss"></div>
                    <Button
                      outline={true}
                      className="buttonDivStyle"
                    >
                      Sign In
                    </Button>
                  </AvForm>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-between footDivStyle">
                <p className="textcenter">© D'hybrid. All Rights Reserved.</p>
                <ul className="d-flex align-items-center">
                  <Link to="/TermsAndConditions">
                    <li className="textcenter">
                      Terms of Use and Privacy Policy
                    </li>
                  </Link>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loggedInUserData: state.Login?.login,
  };
};
export default connect(mapStateToProps, { adminLogin })(Login);
