import { types } from "./actionTypes";
export const fetchProducts = (offset,filter,callback) => ({
  type: types.FETCH_PRODUCTS,
  offset,
  filter,
  callback,
});

export const updateProducts = ( values , callback ) => ({
  type: types.UPDATE_PRODUCTS,
  values,
  callback
});

export const filterProducts = ( searchParam , callback ) => ({
  type: types.FILTER_PRODUCTS,
  searchParam,
  callback
});
