import React from "react";

export const CloseIcon = (props) => {
  console.log(props);

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L1 13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L13 13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const KeyboardArrowDownIcon = () => {
  return (
    <svg
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.73999 1.50391L6.87012 6.63403L12 1.50391"
        stroke="#cccccc"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export const KeyboardArrowUpIcon = () => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.63403L6.86987 1.50391L1.73999 6.63403"
        stroke="#a8b8d0"
        stroke-width="2"
        strokeLinecap="round"
      />
    </svg>
  );
};


export const Search = (props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7751 13.6C11.2545 13.6 14.0751 10.7794 14.0751 7.3C14.0751 3.82061 11.2545 1 7.7751 1C4.2957 1 1.4751 3.82061 1.4751 7.3C1.4751 10.7794 4.2957 13.6 7.7751 13.6Z"
        stroke="#2800FC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M15.475 15L13.375 12.9"
        stroke="#2800FC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
