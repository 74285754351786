import React, { Component } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col } from "reactstrap";
export default class PaginateData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {this.props?.totCnt !== 0 ? (
          <Row xs={12}>
            <Col xs={6}>
              <span>
                Showing {this.props?.fromVal} to {this.props?.toVal} of{" "}
                {this.props?.totCnt} entries
              </span>
            </Col>
            <Col xs={6}>
              <div className="float-right">
                <Pagination
                  page={this.props?.offset}
                  count={this.props?.totPages}
                  color="primary"
                  onChange={this.props?.handleChangePage}
                />
              </div>
            </Col>
          </Row>
        ) : null}
      </>
    );
  }
}
