
export const types = {
  FETCH_COUPON: "FETCH_COUPON",
  FETCH_COUPON_SUCCESS: "FETCH_COUPON_SUCCESS",

  UPDATE_COUPON:"UPDATE_COUPON",
  UPDATE_COUPON_SUCCESS:"UPDATE_COUPON_SUCCESS",

  CREATE_COUPON:"CREATE_COUPON",
  CREATE_COUPON_SUCCESS:"CREATE_COUPON_SUCCESS",
  
  GET_BRAND_LIST:"GET_BRAND_LIST",
  GET_BRAND_LIST_SUCCESS:"GET_BRAND_LIST_SUCCESS",

  DELETE_COUPON:"DELETE_COUPON",
  DELETE_COUPON_SUCCESS:"DELETE_COUPON_SUCCESS"

};
