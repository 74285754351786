import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
var data = {};
export default class ProductTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let rows = this.props?.rows;
    data = {
      columns: [
        {
          label: "Image",
          field: "ProductImage",
          sort: "asc",
          width: 200,
        },
        {
          label: "SKU Name",
          field: "ProductName",
          sort: "asc",
          width: 200,
        },
        {
          label: "SKU Code",
          field: "ProductCode",
          sort: "asc",
          width: 200,
        },

        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 200,
        },
        {
          label: "Slug",
          field: "slug",
          sort: "asc",
          width: 300,
        },

        {
          label: "Price",
          field: "salePrice",
          sort: "asc",
          width: 200,
        },
        {
          label: "Brand",
          field: "brand",
          sort: "asc",
          width: 200,
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 200,
        },
      ],
      rows: rows,
    };
    return (
      <div className="order mt-2">
        <MDBDataTable
          responsive
          sortable
          data={data}
          paging={false}
          className="hover text-center"
          noBottomColumns
          searching={false}
        />
      </div>
    );
  }
}
