import { types } from "./actionTypes";
// BLOG blog Blog
export const fetchBlog = (offset, callback) => ({
  type: types.FETCH_BLOG,
  offset,
  callback,
});

export const fetchTag = (callback) => ({
  type: types.FETCH_TAG,
  callback,
});

export const createBlog = (values, callback) => ({
  type: types.CREATE_BLOG,
  values,
  callback,
});

export const updateBlog = (values, callback) => ({
  type: types.UPDATE_BLOG,
  values,
  callback,
});

export const deleteBlog = (id, callback) => ({
  type: types.DELETE_BLOG,
  id,
  callback,
});

export const getBlogCategories = (data, callback) => ({
  type: types.GET_BLOG_CATEGORIES,
  data,
  callback,
});

export const uploadBlogImage = (data, callback) => ({
  type: types.UPLOAD_BLOG_IMAGE,
  data,
  callback,
});

//blogSlug
export const fetchBlogSlugList = (offset, callback) => ({
  type: types.FETCH_BLOGSLUG_LIST,
  offset,
  callback,
});

export const addBlogSlugList = (values, callback) => ({
  type: types.ADD_BLOGSLUG_LIST,
  values,
  callback,
});

export const updateBlogSlug = (values, callback) => ({
  type: types.EDIT_BLOGSLUG_LIST,
  values,
  callback,
});

export const deleteBlogSlug = (id, callback) => ({
  type: types.DELETE_BLOGSLUG_LIST,
  id,
  callback,
});
