import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
// import { withNamespaces } from "react-i18next";

// users
import avatar2 from "../../../assets/images/user-logo.png";
import { Link } from "react-router-dom";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      
    };
    this.toggle = this.toggle.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  toggleLanguage() {
    this.setState({
      langaugeOpen: !this.state.langaugeOpen,
    });
  }
  componentDidUpdate() {
   
  }
  render() {
    let username = "Admin";
    let authUser = localStorage.getItem("authUser")?"Admin":username;
  
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            style={{
              height: "40px",
            }}
            tag="button"
            className="btn mt-0 pt-0 MobileProfileParent"
            // id="page-header-user-dropdown"
          >
            <img
              className=" mr-1"
              src={avatar2}
              alt="Header Avatar"
              width="56px"
              height="36px"
            />
            <p className="d-xl-inline-block ml-1 text-transform profile-name">
              <span className="dark__span">
                {authUser && authUser.length > 20
                  ? authUser.slice(0, 19) + "..."
                  : authUser}
              </span>
            </p>
            <i className="mdi mdi-chevron-down ml-1 pl-1 d-xl-inline-block mt-0 profile-chervon"></i>
          </DropdownToggle>
          <DropdownMenu right className="darkCardStyle dropDownRightProfile">
            <DropdownItem className="text-danger">
              <Link
                className="nav-link cursor dropdown-toggle arrow-none"
                to="/profileSettings"
                id="topnav-apps"
                role="button"
              >
                <svg
                  className="pr-2 mr-2"
                  width="50"
                  height="50"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_5658_527)">
                    <rect
                      x="4"
                      y="4"
                      width="25"
                      height="25"
                      rx="4"
                      fill="#D5CDFF"
                    />
                  </g>
                  <path
                    d="M21.583 22.906V21.583C21.583 20.8812 21.3042 20.2082 20.808 19.712C20.3118 19.2158 19.6388 18.937 18.937 18.937H13.646C12.9442 18.937 12.2712 19.2158 11.775 19.712C11.2788 20.2082 11 20.8812 11 21.583V22.906"
                    fill="#2800FC"
                  />
                  <path
                    d="M21.583 22.906V21.583C21.583 20.8812 21.3042 20.2082 20.808 19.712C20.3118 19.2158 19.6388 18.937 18.937 18.937H13.646C12.9442 18.937 12.2712 19.2158 11.775 19.712C11.2788 20.2082 11 20.8812 11 21.583V22.906H21.583Z"
                    stroke="#2800FC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.083 16C17.4637 16 18.583 14.8807 18.583 13.5C18.583 12.1193 17.4637 11 16.083 11C14.7023 11 13.583 12.1193 13.583 13.5C13.583 14.8807 14.7023 16 16.083 16Z"
                    fill="#2800FC"
                    stroke="#2800FC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_d_5658_527"
                      x="0"
                      y="0"
                      width="33"
                      height="33"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5658_527"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5658_527"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
               My Profile
              </Link>
            </DropdownItem>
            <hr className="hrLineProfile"></hr>
            <DropdownItem className="text-danger">
              {" "}
              <Link
                className="nav-link cursor dropdown-toggle arrow-none"
                to="/logout"
                id="topnav-apps"
                role="button"
              >
                <svg
                  className="pr-2 mr-2"
                  width="50"
                  height="50"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_5132_102)">
                    <rect
                      x="4"
                      y="4"
                      width="25"
                      height="25"
                      rx="4"
                      fill="#D5CDFF"
                    />
                  </g>
                  <path
                    d="M14.6667 22H12.2222C11.8981 22 11.5872 21.8712 11.358 21.642C11.1288 21.4128 11 21.1019 11 20.7778V12.2222C11 11.8981 11.1288 11.5872 11.358 11.358C11.5872 11.1288 11.8981 11 12.2222 11H14.6667"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.9443 19.5556L21.9999 16.5L18.9443 13.4445"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22.0003 16.5H14.667"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_d_5132_102"
                      x="0"
                      y="0"
                      width="33"
                      height="33"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5132_102"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5132_102"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>

                {/* <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "} */}
                <span className="light__span">
                 Logout
                </span>
              </Link>
            </DropdownItem>
        
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default ProfileMenu;
