import React, { Component } from "react";
import { Button } from "reactstrap";
import search from "./assets/search.png";
import { InputGroup, Input } from "reactstrap";
import { Grid } from "@material-ui/core";

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidUpdate() {}
  render() {
    return (
      <React.Fragment>
        <Grid
          className="breadcrumb p-0"
          container
          spacing={2}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={6} md={8}>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <i className="fa fa-tag fa-sm"></i> Product
              </li>
              <li className="breadcrumb-item active">Product List</li>
            </ol>
          </Grid>
          <Grid item xs={6} md={4}>
            <div className="d-flex justify-content-center flex-fill">
              <div className="p-2 quickStylesBar">
              <div className="input-border">
                  <InputGroup>
                    <Input
                      className="form-control "
                      type="text"
                      name="search"
                      placeholder="Search By SKU,Brand,etc..."
                      aria-label="Search"
                      value={this.props?.productSearchInput}
                      onKeyDown={(event) => this.props?.enterCheck(event)}
                      onChange={(e) =>
                        this.props?.filterAssigner(e, "search")
                      }
                    />
                    <span className="pt-2 pb-2 input-line lineDark pl-1 ml-1"></span>
                    <span
                      className="span-img"
                      onClick={() => this.props?.filterList()}
                    >
                      <img className="pl-1" src={search} alt=""></img>
                    </span>
                  </InputGroup>
                </div>
              </div>
              <div className="p-2 desktopDisplay">
                <Button
                  size="sm"
                  color="success"
                  onClick={() => this.props?.reset()}
                >
                  RESET
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
