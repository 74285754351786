import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
var data = {};
export default class CouponTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let rows = this.props?.rows;
    data = {
      columns: [
        {
          label: "Code",
          field: "code",
          sort: "asc",
          width: 200,
        },

        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 200,
        },

        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 200,
        },

        {
          label: "Brand",
          field: "brand",
          sort: "asc",
          width: 200,
        },

        {
          label: "Slug",
          field: "slug",
          sort: "asc",
          width: 200,
        },
        
        {
          label: "Discount(%)",
          field: "discountInPercentage",
          sort: "asc",
          width: 200,
        },
         
        {
          label: "Start Date",
          field: "startDate",
          sort: "asc",
          width: 200,
        },
         
        {
          label: "End date",
          field: "endDate",
          sort: "asc",
          width: 200,
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 200,
        },
      ],
      rows: rows,
    };
    return (
      <div className="order mt-2">
        <MDBDataTable
          responsive
          sortable
          data={data}
          paging={false}
          className="hover text-center"
          noBottomColumns
          searching={false}
        />
      </div>
    );
  }
}
