// import { mapKeys } from 'lodash';
import { types } from "../actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
      case types.AUTH_LOGIN_SUCCESS:
        return { authLogin: action.payload };
        case types.LOGOUT_USER_SUCCESS:
          return { authLogin: action.payload };
    default:
      state = { ...state };
      break;
  }
 
  return state;
};
