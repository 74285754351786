
export const types = {
  FETCH_BLOG: "FETCH_BLOG",
  FETCH_BLOG_SUCCESS: "FETCH_BLOG_SUCCESS",
  
  FETCH_TAG:"FETCH_TAG",
  FETCH_TAG_SUCCESS:"FETCH_TAG_SUCCESS",

  CREATE_BLOG:"CREATE_BLOG",
  CREATE_BLOG_SUCCESS:"CREATE_BLOG_SUCCESS",

  UPDATE_BLOG:"UPDATE_BLOG",
  UPDATE_BLOG_SUCCESS:"UPDATE_BLOG_SUCCESS",
  
  GET_BLOG_CATEGORIES:"GET_BLOG_CATEGORIES",
  GET_BLOG_CATEGORIES_SUCCESS:"GET_BLOG_CATEGORIES_SUCCESS",

  UPLOAD_BLOG_IMAGE:"UPLOAD_BLOG_IMAGE",
  UPLOAD_BLOG_IMAGE_SUCCESS:" UPLOAD_BLOG_IMAGE_SUCCESS",


  //BLOG SLUG

  FETCH_BLOGSLUG_LIST: "FETCH_BLOGSLUG_LIST",
  FETCH_BLOGSLUG_LIST_SUCCESS: "FETCH_BLOGSLUG_LIST_SUCCESS",


  ADD_BLOGSLUG_LIST: "ADD_BLOGSLUG_LIST",
  ADD_BLOGSLUG_LIST_SUCCESS: "ADD_BLOGSLUG_LIST_SUCCESS",

  EDIT_BLOGSLUG_LIST: "EDIT_BLOGSLUG_LIST",
  EDIT_BLOGSLUG_LIST_SUCCESS: "EDIT_BLOGSLUG_LIST_SUCCESS",

  DELETE_BLOGSLUG_LIST: "DELETE_BLOGSLUG_LIST",
  DELETE_BLOGSLUG_LIST_SUCCESS: "DELETE_BLOGSLUG_LIST_SUCCESS",

  DELETE_BLOG:"DELETE_BLOG",
  DELETE_BLOG_SUCCESS:"DELETE_BLOG_SUCCESS"

  


};
