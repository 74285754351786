import { types } from "./actionTypes";
export const fetchCoupon = (values, callback) => ({
  type: types.FETCH_COUPON,
  values,
  callback,
});

export const updateCoupon = ( values, callback) => ({
  type: types.UPDATE_COUPON,
  values,
  callback,
});

export const brandList = (values, callback) => ({
  type: types.GET_BRAND_LIST,
  values,
  callback,
});

export const deleteCoupon = (id, callback) => ({
  type: types.DELETE_COUPON,
  id,
  callback,
});

export const addCoupon = (values, callback) => ({
  type: types.CREATE_COUPON,
  values,
  callback,
});
