import React, { Component } from "react";
import Select from "react-select";
import { updateProducts } from "../../store/products/actions";
import { NotificationManager } from "react-notifications";
import { fetchSlug } from "../../store/slug/actions";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  CardBody,
  Card,
} from "reactstrap";

class viewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SKU: "",
      skuCode: "",
      category: " ",
      salePrice: "",
      size: "",
      quantity: "",
      vendor: "",
      image1: "",
      mode: "",
      optionSlug: [],
      selectedGroup: [],
    };
  }

  componentDidMount = () => {
    this.getData();
    if (this.props?.location?.mode) {
      let modeStatus = this.props?.location?.mode;
      this.setState({ mode: modeStatus }, () => {
        if (this.state.mode === "edit") {
          this.props.fetchSlug(this.state.category, (res) => {
            this.optionGroup(res);
          });
        }
      });
    }
  };

  optionGroup = (res) => {
    let optionGroupArr = [];
    let data = res?.response?.body ? res?.response?.body : [];
    if (data && data.length > 0) {
      data.map((slugOption) => {
        optionGroupArr.push({
          label: slugOption?.slugName,
          value: slugOption?.slugId,
        });
        return true;
      });
    }
    this.setState({ optionSlug: optionGroupArr });
  };
  handleSubmit = () => {
    let slugArr = [];
    const { category, selectedGroup } = this.state;
    if (selectedGroup?.length > 0 && selectedGroup !== null) {
      selectedGroup.map((slug) => {
        slugArr.push({
          category: category,
          slugName: slug?.label,
        });
        return slugArr;
      });
    }

    let formObj = {
      skuCodes: [this.state.skuCode ? this.state.skuCode : ""],
      slug: slugArr.length ? slugArr : [],
    };

    this.props.updateProducts(formObj, (res) => {
      if (res?.response?.body?.code === 200) {
        NotificationManager.success("Updated Successfully", "Success");
        // this.props.history.push("/product");
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  getData = () => {
    if (this.props.location.productById) {
      let data = this.props?.location?.productById;

      this.setState(
        {
          SKU: data?.SKU ? data?.SKU : " ",
          skuCode: data?.skuCode ? data?.skuCode : " ",
          vendor: data?.vendor ? data?.vendor : " ",
          selectedGroup: data.slug ? data.slug : "Select..",
          category: data?.category ? data?.category : "",
          salePrice: data?.salePrice ? data?.salePrice : "",
          size: data?.size ? data?.size : "",
          quantity: data?.quantity ? data?.quantity : "",
          image1: data?.image ? data?.image : "",
        },
        () => {}
      );
    }
  };
  onChangeInput = (name, val) => {
    this.setState({
      [`${name}`]: val,
    });
  };

  //Select
  handleSelectGroup = (selectedGroup) => {
    this.setState({ selectedGroup }, () => {});
  };

  render() {
    const { selectedGroup, mode } = this.state;
    return (
      <React.Fragment>
        <Container fluid={true} className="mt-2">
          <Row>
            <Col xl="12" className="p-1">
              <Card>
                <CardBody style={{ padding: "2%" }}>
                  <Form>
                    <Row className="mt-3">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            SKU Code
                          </Label>
                          <Input
                            name="skuCode"
                            placeholder="SKU Code"
                            type="text"
                            value={this.state.skuCode}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            disabled={mode === "view" ? true : true}
                            onChange={(e) =>
                              this.onChangeInput("skuCode", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            SKU
                          </Label>
                          <Input
                            name="SKU"
                            placeholder="SKU"
                            type="textarea"
                            rows="1"
                            value={this.state.SKU}
                            className="form-control-lg"
                            id="validationCustom01"
                            disabled={mode === "view" ? true : true}
                            onChange={(e) =>
                              this.onChangeInput("SKU", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6"></Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Category
                          </Label>
                          <Input
                            name="category"
                            placeholder="Category"
                            type="text"
                            value={this.state.category}
                            className="form-control-lg"
                            id="validationCustom02"
                            disabled={mode === "view" ? true : true}
                            onChange={(e) =>
                              this.onChangeInput("category", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Size
                          </Label>
                          <Input
                            name="size"
                            placeholder="Size"
                            type="text"
                            value={this.state.size}
                            className="form-control-lg"
                            id="validationCustom04"
                            disabled={mode === "view" ? true : true}
                            onChange={(e) =>
                              this.onChangeInput("size", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Quantity
                          </Label>
                          <Input
                            name="quantity"
                            placeholder="Quantity"
                            className="form-control-lg"
                            type="text"
                            value={this.state.quantity}
                            id="validationCustom03"
                            disabled={mode === "view" ? true : true}
                            onChange={(e) =>
                              this.onChangeInput("quantity", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Vendor Name
                          </Label>
                          <Input
                            name="vendor"
                            placeholder="Vendor Name"
                            type="text"
                            value={this.state.vendor}
                            className="form-control-lg"
                            id="validationCustom04"
                            disabled={mode === "view" ? true : true}
                            onChange={(e) =>
                              this.onChangeInput("vendor", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md="6">
                        <FormGroup className="select2-container">
                          <Label className="control-label fontRoboto127">
                            Slug Select
                          </Label>
                          <Select
                            value={selectedGroup}
                            isMulti={true}
                            onChange={this.handleSelectGroup}
                            options={this.state.optionSlug}
                            classNamePrefix="select2-selection"
                            isDisabled={mode === "view" ? true : false}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Sale Price
                          </Label>
                          <Input
                            name="salePrice"
                            placeholder="Sale Price"
                            type="text"
                            value={this.state.salePrice}
                            className="form-control-lg"
                            id="validationCustom03"
                            disabled={mode === "view" ? true : true}
                            onChange={(e) =>
                              this.onChangeInput("salePrice", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {mode !== "edit" ? (
                      <Row className="mt-">
                        <Col md="6">
                          <div className="fontRoboto127 mb-0 pb-0">Image</div>
                          <div className="mt-0 p-0 squareImg product-img">
                            <img src={this.state.image1} alt="" className="img-fluid"></img>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    {mode !== "view" ? (
                      <Button
                        color="primary"
                        // type="submit"
                        className="mt-4"
                        onClick={this.handleSubmit}
                      >
                        UPDATE
                      </Button>
                    ) : (
                      ""
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.Product.product,
});

export default connect(mapStateToProps, {
  updateProducts,
  fetchSlug,
})(viewProduct);
