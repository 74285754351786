import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import { MoonLoader } from "react-spinners";
import { fetchBlog, deleteBlog } from "../../store/blogs/actions";
import { connect } from "react-redux";
import BlogTable from "./BlogTable";
import EditBlogModel from "./EditBlogModel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { Grid } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Pagination from "../Common/PaginateData";
import BlogPreview from "./BlogPreview";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 1;

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      editBlogModel: false,
      blogPreviewModel: false,
      currBlogPreview: "",
      editByIdData: "",
    };
  }

  componentDidMount = () => {
    this.getAllData();
  };

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 1;
  };

  resetBlog = () => {
    offset = 1;
    this.getAllData();
  };
  deleteBlog = (id) => {
    this.props.deleteBlog(id, (res) => {
      if (res) {
        NotificationManager.success("Deleted Successfully", "Success");
        this.getAllData();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  getAllData = () => {
    this.setState({ loading: true });
    // "blogStatus": "PUBLISHED",
    // "numberOfRecord": 10,
    // "pageNumber": 1,
    this.props.fetchBlog(
      { blogStatus: "PUBLISHED", numberOfRecord: 10, pageNumber: offset },
      (res) => {
        this.refineData(res);
      }
    );
  };
  refineData(res) {
    let rowpro = [];
    let slug;
    if (res && res?.response?.body?.blogApiResponseList?.length > 0) {
      let blogs = res?.response?.body?.blogApiResponseList;

      totCnt = res?.response?.body?.count;
      fromVal = totCnt !== 0 ? (offset - 1) * 10 + 1 : fromVal;
      toVal = (offset - 1) * 10 + blogs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);

      blogs.forEach((element, index, array) => {
        slug = element?.slug
          ? element?.slug.map((element) => {
              return <div>{element?.slugName}</div>;
            })
          : "N/A";

        if (element) {
          rowpro.push({
            images: element?.images?.length ? (
              <div style={{ cursor: "pointer" }}>
                <img
                  src={element.images[0]?.imageData}
                  width="42px"
                  height="50px"
                  alt=""
                ></img>
              </div>
            ) : (
              "N/A"
            ),
            title: element?.title ? element?.title : "N/A",
            subTitle: element?.subTitle ? element?.subTitle : "N/A",
            content: element?.body ? element?.body : "N/A",
            status:
              element?.blogStatus === "PUBLISHED" ? (
                <div className="badge badge-soft-success font-size-14">
                  {element?.blogStatus}
                </div>
              ) : element?.blogStatus === "DRAFT" ? (
                <div className="badge badge-soft-warning font-size-14">
                  {element?.blogStatus}
                </div>
              ) : element?.blogStatus === "ARCHIVED" ? (
                <div className="badge badge-soft-danger font-size-14">
                  {element?.blogStatus}
                </div>
              ) : (
                "N/A"
              ),
            rootCategory: element?.rootCategory ? element?.rootCategory : "N/A",
            slugName: slug?.length ? slug : "N/A",
            tag: element?.tags?.length ? element?.tags[0]?.tag : "N/A",
            actions: (
              <div className="d-flex align-items-center tableBtns ">
                <Button
                  outline
                  color="primary"
                  size="md"
                  onClick={() => {
                    this.blogModel(element);
                  }}
                >
                  <ModeEditIcon />
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  outline
                  color="primary"
                  size="md"
                  onClick={() => {
                    this.blogPreview(element);
                  }}
                >
                  <RemoveRedEyeRoundedIcon fontSize="small" />
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  outline
                  color="action"
                  className="badge-soft-danger"
                  size="md"
                  onClick={() => {
                    if (window.confirm("Are you sure to delete this record?")) {
                      this.deleteBlog(element?.id);
                    }
                  }}
                >
                  <DeleteForeverIcon fontSize="small" color="error" />
                </Button>{" "}
                &nbsp;&nbsp;
              </div>
            ),
          });
        }
      });
      this.setState({ rows: rowpro, loading: false });
    } else {
      this.setState({ loading: false }, () => {
        NotificationManager.error(res?.error, "Error");
      });
    }
  }
  blogModel = (data) => {
    this.setState({ editBlogModel: !this.state.editBlogModel }, () => {
      this.setState({ editByIdData: data });
    });
  };
  blogCloseModel = () => {
    this.setState({ editBlogModel: false }, () => {
      this.getAllData();
    });
  };
  blogPreview = (preview) => {
    this.setState({ blogPreviewModel: !this.state.blogPreviewModel }, () => {
      this.setState({ currBlogPreview: preview });
    });
  };
  handleChangePage = (event, val) => {
    this.setState({ loading: true });

    offset = val;
    this.getAllData();
  };

  render() {
    const { loading, rows } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    return (
      <div className="mt-1">
        <Container fluid>
          <Row>
            <Col xs={12} className="p-1">
              <Card>
                <CardBody>
                  <Grid
                    className="breadcrumb p-0"
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={6} md={8}>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <i className="fas fa-blog"></i> Blog
                        </li>
                        <li className="breadcrumb-item active">Blog List</li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <div className="d-flex justify-content-center flex-fill">
                        <div className="p-2">
                          <Link to="/addBlog">
                            <Button size="sm" color="primary">
                              Add Blog
                            </Button>
                          </Link>
                        </div>
                        <div className="p-2">
                          <Button
                            size="sm"
                            color="success"
                            onClick={this.resetBlog}
                          >
                            RESET
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <BlogTable rows={rows ? rows : []} />
                  <Pagination
                    fromVal={fromVal}
                    toVal={toVal}
                    totCnt={totCnt}
                    offset={offset}
                    totPages={totPages}
                    handleChangePage={this.handleChangePage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <EditBlogModel
          isOpen={this.state.editBlogModel}
          toggleModel={this.blogModel}
          closeModel={this.blogCloseModel}
          rowsData={this.state.editByIdData}
        />
        <BlogPreview
          isOpen={this.state.blogPreviewModel}
          toggleModel={this.blogPreview}
          previewData={this.state.currBlogPreview}
        />
      </div>
    );
  }
}
export default connect(null, { fetchBlog, deleteBlog })(Blog);
