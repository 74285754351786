import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
var data = {};
export default class BlogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let rows = this.props?.rows;
    data = {
      columns: [
        {
          label: "Images",
          field: "images",
          sort: "asc",
          width: 200,
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 200,
        },
        {
          label: "Sub Title",
          field: "subTitle",
          sort: "asc",
          width: 200,
        },
        {
          label: "Content",
          field: "content",
          sort: "asc",
          width: 200,
        },

        {
          label: "Category",
          field: "rootCategory",
          sort: "asc",
          width: 200,
        },
        {
          label: "Slugs",
          field: "slugName",
          sort: "asc",
          width: 200,
        },
        {
          label: "Tag",
          field: "tag",
          sort: "asc",
          width: 200,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 200,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 200,
        },
      ],
      rows: rows,
    };
    return (
      <div className="order mt-4">
        <MDBDataTable
          responsive
          sortable
          data={data}
          paging={false}
          className="hover text-center"
          noBottomColumns
          searching={false}
        />
      </div>
    );
  }
}
