
export const types = {
  FETCH_PRODUCTS: "FETCH_PRODUCTS",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",

  UPDATE_PRODUCTS:"UPDATE_PRODUCTS",
  UPDATE_PRODUCTS_SUCCESS:"UPDATE_PRODUCTS_SUCCESS",

  FILTER_PRODUCTS:"FILTER_PRODUCTS",
  FILTER_PRODUCTS_SUCCESS:"FILTER_PRODUCTS_SUCCESS"

};
