import React, { Component } from "react";
import { updateBlogSlug } from "../../store/blogs/actions";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  CardBody,
  Card,
} from "reactstrap";
const categoryOption = [
  { label: "Grooming", value: "Grooming" },
  { label: "Style", value: "Style" },
  { label: "Lifestyle", value: "Lifestyle" },
];
const displayFormat = [
  { label: "Carousel", value: "Carousel" },
  { label: "Single", value: "Single" },
];
class EditBlogSlug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  reset = () => {
    this.setState({
      data: {},
    });
  };
  render() {
    let editData = this.props?.history?.location?.slug;
    return (
      <React.Fragment>
        <Container fluid={true} className="mt-2 bg-white">
          <Row className="content">
            <Col xl="12" className="p-2 mt-2">
              <Card>
                <section>
                  <h4 className="text-center mt-2">Update Slug</h4>
                </section>
                <CardBody style={{ padding: "5%" }}>
                  <Formik
                    onSubmit={(values) => {
                      console.log("submitvalue", values);
                      this.setState({data:values}, () => {
                        this.props.updateBlogSlug(values, (res) => {
                          if (res?.response?.length>0) {
                            NotificationManager.success(
                              "Updated Successfully",
                              "Success"
                            );
                            this.props.history.push("/viewBlogSlug");
                          } else {
                            NotificationManager.error(res["message"], "Error");
                          }
                        });
                      });
                    }}
                    initialValues={{
                      id:editData?.slugId,
                      slugName: editData?.slugName,
                      category: editData?.category,
                      sequence: editData?.sequence,
                      displayFormat: editData?.displayFormat,
                    }}

                    validationSchema={yup.object().shape({
                      sequence: yup
                        .number()
                    })}
                  >
                    {({
                      handleSubmit,
                      dirty,
                      isSubmitting,
                      handleReset,
                      handleChange,
                      handleBlur,
                      values,

                      touched,
                      isValid,
                      errors,

                      setFieldValue,
                      setFieldTouched,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mt-2">
                          <Col md="12">
                            <FormGroup>
                              <Label
                                htmlFor="example-text-input"
                                className="fontRoboto127 mb-2"
                              >
                                Slug Name
                              </Label>
                              <Input
                                name="slugName"
                                placeholder="SlugName"
                                value={values.slugName}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="slugName"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md="12">
                            <FormGroup>
                              <Label
                                htmlFor="example-text-input"
                                className="fontRoboto127 mb-2"
                              >
                                Category
                              </Label>
                              <Select
                                defaultValue={categoryOption.find(
                                  (option) =>
                                    option.value === values.category
                                )}
                                name="category"
                                selected={values.category}
                                options={categoryOption}
                                onChange={(e) => {
                                  console.log(e);
                                  setFieldValue("category", e.label);
                                }}
                                onBlur={handleBlur}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col md="12">
                            <FormGroup>
                              <Label
                                htmlFor="example-text-input"
                                className="fontRoboto127 mb-2"
                              >
                                Display Format
                              </Label>

                              <Select
                                defaultValue={displayFormat.find(
                                  (option) =>
                                    option.value === values.displayFormat
                                )}
                                name="displayFormat"
                                selected={values.displayFormat}
                                options={displayFormat}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  console.log(e.label);
                                  setFieldValue("displayFormat", e.label);
                                }}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col md="12">
                            <FormGroup>
                              <Label
                                htmlFor="example-text-input"
                                className="fontRoboto127 mb-2"
                              >
                                Sequence
                              </Label>
                              <Input
                                name="sequence"
                                placeholder="Sequence "
                                type="number"
                                value={values.sequence}
                                id="sequence"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={
                                  errors.sequence && touched.sequence
                                    ? "input-error"
                                    : null
                                }
                              />
                               <ErrorMessage
                                name="sequence"
                                component="span"
                                className="error"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Button
                          color="primary"
                          type="submit"
                          className="mt-4 mr-3"
                        >
                          UPDATE
                        </Button>

                        <Button
                          color="success"
                          type="button"
                          className="mt-4"
                          onClick={handleReset}
                        >
                          RESET
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(null,{updateBlogSlug})(EditBlogSlug);
