import React, { Component } from "react";
import {
  createBlog,
  getBlogCategories,
  fetchTag,
  uploadBlogImage,
} from "../../store/blogs/actions";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import jwt_decode from "jwt-decode";
import { logoutUserSuccess } from "../../store/auth/actions";
import { MoonLoader } from "react-spinners";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  CardBody,
  Card,
} from "reactstrap";

class AddBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      loading: false,
      images: [],
      selectedImg: "",
      rootCategory: "",
      displayFormat: "",
      sequence: "",
      selectedTag: "",
      optionTag: [],
      title: "",
      subTitle: "",
      optionSlug: [],
      selectedFiles: [],
      errors: {},
    };

    this.myRef = React.createRef(null);
  }

  componentDidMount = () => {
    this.props.fetchTag((res) => {
      if (res?.response?.body?.length > 0) {
        let tagArr = [];
        let data = res?.response?.body;
        data &&
          data.map((res) => {
            tagArr.push({ label: res?.tag, value: res?.tag });
            return true;
          });
        this.setState({ optionTag: tagArr });
      }
    });
  };

  onChangeInput = (name, val) => {
    this.setState(
      {
        [`${name}`]: val,
      },
      () => {}
    );
  };

  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files }, () => {
      if (this.state.selectedFiles.length) {
        let imgArr = [];
        let img = this.state.selectedFiles?.map((res) => {
          return imgArr.push(res?.name);
        });
        console.log(img);
        this.setState({ images: imgArr });
        var formdata = new FormData();
        formdata.append(
          "file",
          this.state.selectedFiles[0],
          this.state.selectedFiles[0].name
        );
        this.setState({ loading: true });
        this.props.uploadBlogImage(formdata, (result) => {
          NotificationManager.success("Image upload Success", "Success");
          this.setState({ loading: false ,selectedImg: result });
        });
      }
    });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  removeFileselection = (file, i) => {
    this.setState({ selectedFiles: [], images: [] });
  };
  //Select
  handleSelectGroup = (selectedGroup) => {
    this.setState({ selectedGroup }, () => {});
  };
  handleTag = (selectedTag) => {
    this.setState({ selectedTag }, () => {});
  };
  handleSelectBlogCatGroup = (selectBlogCayegory) => {
    this.setState({ rootCategory: selectBlogCayegory }, () => {
      if (this.state.rootCategory) {
        let cat = this.state?.rootCategory?.value;
        this.props.getBlogCategories(cat, (res) => {
          console.log(res, "products");
          this.optionGroup(res);
        });
      }
    });
  };
  optionGroup = (res) => {
    let optionGroupArr = [];
    let data = res?.response?.body ? res?.response?.body : [];
    if (data && data.length > 0) {
      data.map((slugOption) => {
        optionGroupArr.push({
          label: slugOption?.slugName,
          value: slugOption?.slugId,
        });
        return true;
      });
    }
    this.setState({ optionSlug: optionGroupArr });
  };
  validateValAdd = () => {
    const errorsAdd = {};
    let {
      rootCategory,
      title,
      selectedGroup,
      content,
      selectedImg,
      selectedTag,
      subTitle,
    } = this.state;
    // let image = this.state.urlArrayValues;

    if (!rootCategory) {
      errorsAdd.rootCategory = "Category is required!";
    }
    if (!title) {
      errorsAdd.title = "Title is required!";
    }
    if (!selectedGroup) {
      errorsAdd.selectedGroup = "Slug is required!";
    }
    if (!selectedTag) {
      errorsAdd.selectedTag = "Tag is required!";
    }
    if (!content) {
      errorsAdd.content = "Content is required!";
    }
    if (!subTitle) {
      errorsAdd.subTitle = "Sub Title is required!";
    }

    if (!selectedImg) {
      errorsAdd.selectedImg = "Image is required!";
    }

    return errorsAdd;
  };

  handleSubmit = () => {
    let slugArr = [];
    var tokenAuth = JSON.parse(window.localStorage.getItem("token"))
      ? JSON.parse(window.localStorage.getItem("token"))
      : " ";

    const {
      rootCategory,
      title,
      selectedGroup,
      content,
      selectedTag,
      subTitle,
    } = this.state;
    let slug = [
      {
        category: rootCategory?.label,
        slugName: selectedGroup?.label,
      },
    ];

    let formObj = {
      title: title ? title : "",
      subTitle: subTitle ? subTitle : "",
      body: content ? content : "",
      tags: [{ tag: selectedTag?.value }],
      images: [this.state.selectedImg],
      rootCategory: rootCategory?.value ? rootCategory?.value : "",
      slug: slug ? slug : [],
      userAcessToken: tokenAuth ? tokenAuth : "",
      blogStatus: "DRAFT",
    };
    console.log(formObj, slugArr, "formobj");
    this.setState({ errors: this.validateValAdd(formObj) }, () => {
      if (Object.keys(this.state.errors).length === 0) {
        this.props.createBlog(formObj, (res) => {
          if (res?.code === 200 || res?.code === 201) {
            NotificationManager.success("Draft Added Successfully", "Success");
            this.props.history.push("/viewBlog");
          } else {
            NotificationManager.error(res["message"], "Error");
          }
        });
      } else {
        NotificationManager.error("Required All fields", "Error");
      }
    });
  };

  render() {
    const { selectedGroup, optionTag, errors, loading } = this.state;
    const blogCategoryOption = [
      { label: "Style", value: "Style" },
      { label: "Grooming", value: "Grooming" },
      { label: "Lifestyle", value: "Lifestyle" },
    ];

    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    return (
      <div>
        <section class="content-header">
          <h1>
            <small></small>
          </h1>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <i class="fas fa-blog"></i> <Link to="/viewBlog"><span className="blogHead">Blog</span></Link>
            </li>
            <li class="breadcrumb-item active">Add Blog</li>
          </ol>
        </section>
        <Container fluid={true}>
          <Row>
            <Col xl="12" className="p-0">
              <Card>
                <CardBody style={{ padding: "2%" }}>
                  <Form>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Title
                          </Label>
                          <Input
                            name="title"
                            placeholder="Title"
                            type="text"
                            value={this.state.title}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("title", e.target.value)
                            }
                          />
                          {errors?.title && (
                            <div className="error">{errors?.title} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Sub Title
                          </Label>
                          <Input
                            name="subTitle"
                            placeholder="Sub Title"
                            type="text"
                            value={this.state.subTitle}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("subTitle", e.target.value)
                            }
                          />
                          {errors?.subTitle && (
                            <div className="error">{errors?.subTitle} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Content
                          </Label>
                          <Input
                            name="content"
                            placeholder="content"
                            type="textarea"
                            rows="3"
                            value={this.state.content}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("content", e.target.value)
                            }
                          />
                          {errors?.content && (
                            <div className="error">{errors?.content} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="4">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Blog Category
                          </Label>
                          <Select
                            className="form-control-xs"
                            onChange={this.handleSelectBlogCatGroup}
                            options={blogCategoryOption}
                            value={this.state.rootCategory}
                            classNamePrefix="select2-selection"
                          />
                          {errors?.rootCategory && (
                            <div className="error">{errors?.rootCategory} </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <FormGroup className="select2-container">
                          <Label className="control-label fontRoboto127">
                            Slug Select
                          </Label>
                          <Select
                            value={selectedGroup}
                            isMulti={false}
                            onChange={this.handleSelectGroup}
                            options={this.state.optionSlug}
                            classNamePrefix="select2-selection"
                          />
                          {errors?.selectedGroup && (
                            <div className="error">
                              {errors?.selectedGroup}{" "}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Tag
                          </Label>
                          <Select
                            className="form-control-xs"
                            onChange={this.handleTag}
                            value={this.state.selectedTag}
                            options={optionTag}
                            classNamePrefix="select2-selection"
                          />
                          {errors?.selectedTag && (
                            <div className="error">{errors?.selectedTag} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md="12">
                        <p className="card-title-desc">Upload Blog image</p>
                        <Form>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              this.handleAcceptedFiles(acceptedFiles)
                            }
                            accept="image/png, image/jpeg"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />

                                  <div className="mb-3">
                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                  <em>
                                    (Only *.jpeg and *.png images will be
                                    accepted)
                                  </em>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          {errors?.selectedImg && (
                            <div className="error">{errors?.selectedImg} </div>
                          )}
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {this.state.selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                        <div style={{ flexGrow: "1" }}>
                                          <i
                                            className="fas fa-times"
                                            style={{
                                              cursor: "pointer",
                                              color: "red",
                                            }}
                                            onClick={() =>
                                              this.removeFileselection(f, i)
                                            }
                                          ></i>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </Form>
                      </Col>
                    </Row>

                    <Button
                      color="primary"
                      // type="submit"
                      className="mt-4"
                      onClick={this.handleSubmit}
                    >
                      SAVE AS DRAFT
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(null, {
  createBlog,
  getBlogCategories,
  logoutUserSuccess,
  fetchTag,
  uploadBlogImage,
})(AddBlog);
