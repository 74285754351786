// import { mapKeys } from 'lodash';
import { types } from "../actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_PRODUCTS_SUCCESS:
      return { prodList: action.payload };
    case types.UPDATE_PRODUCTS_SUCCESS:
      return { updatedProduct: action.payload };
      case types.FILTER_PRODUCTS_SUCCESS:
        return { filterProduct: action.payload };
      
    default:
      state = { ...state };
      break;
  }
 
  return state;
};
