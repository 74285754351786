import React, { Component } from "react";
import "../HorizontalLayout/header.css";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import toggle from "../../assets/images/ToggleGroup13563.png";
import VerticalMenuBar from "../VerticalMenuBar/index";
import { changeSwitchTheme } from "../../store/layout/actions";

class HeaderNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      userLangs: undefined,
      show: false,
    };
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  componentDidUpdate() {}

  closeMenuBar = (closeIcon) => {
    // console.log("close", closeIcon);
    this.setState({
      show: closeIcon,
    });
  };
  render() {
    const path = this.props.location.pathname;

    return (
      <>
        {/* <Card id="page-topbar-new"> */}
        <header className="shadow page-topbar-new">
          <div
            className={
              this.props?.history?.location?.pathname ===
              "/ontime-delivery-report"
                ? "navbar-header-new-scroll"
                : "navbar-header-new"
            }
            // className="navbar-header-new"
          >
            <div className="d-flex">
              <div className="mobile-toggle ml-1 mr-2">
                <img
                  onClick={() => {
                    this.setState({ show: !this.state.show }, () => {});
                  }}
                  src={toggle}
                  height="15px"
                  width="18px"
                  alt=""
                ></img>
                <VerticalMenuBar
                  show={this.state.show}
                  closeMenuBar={this.closeMenuBar}
                />
              </div>
              <div className="">
                {path === "/product" ? (
                  <>
                    <div className="d-flex align-items-center rightSideNavbarLeftDiv">
                      <span className="span-font-roboto ml-2">Dashboard</span>
                      <span className="breadcrumbSeparator ml-2">|</span>
                      <span className="span-font-roboto breadactive ml-2">
                        Products
                      </span>
                    </div>
                  </>
                ) : path === "/viewProducts" ? (
                  <>
                    <div className="d-flex align-items-center rightSideNavbarLeftDiv">
                     <Link to="/product"> <span className="span-font-roboto ml-2 linkText">Products</span></Link>
                      <span className="breadcrumbSeparator ml-2">|</span>
                      <span className="span-font-roboto breadactive ml-2">
                        View Products
                      </span>
                    </div>
                  </>
                ) : path === "/addSlug" ? (
                  <>
                    <span className="span-font-roboto ml-2">
                      Slug
                    </span>
                    <span className="breadcrumbSeparator ml-2">|</span>
                    <span className="span-font-roboto breadactive ml-2">
                     Add Slug
                    </span>
                  </>
                ) : path === "/indent-planning" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                    </span>
                  </>
                ) : path === "/add-indent" ? (
                  <>
                    <div className="mobileAddIndent">
                      <Link to="/indent-planning">
                        <span className="breadcrumbInactive breadcrumb-active span-font-roboto ">
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto ">
                        {this.state.userLangs?.planning?.INDENT_PLANNING}
                      </span>
                    </div>
                    <div className="desktopAddIndent">
                      <Link to="/indent-planning">
                        <span className="breadcrumbInactive breadcrumb-active span-font-roboto">
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto breadactive">
                        {this.state.userLangs?.planning?.INDENT_PLANNING}
                      </span>
                    </div>
                  </>
                ) : path === "/Route-Optimization" ? (
                  <>
                    <div className="mobileAddIndent">
                      <Link to="/Route-Optimization">
                        <span className="breadcrumbInactive breadcrumb-active span-font-roboto ">
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto ">
                        {"Route-Optimization"}
                        {/* {this.state.userLangs?.planning?.INDENT_PLANNING} */}
                      </span>
                    </div>
                    <div className="desktopAddIndent">
                      <Link to="/Route-Optimization">
                        <span className="breadcrumbInactive breadcrumb-active span-font-roboto">
                          {this.state.userLangs?.dashboard?.INDENT_DETAILS}
                        </span>
                      </Link>
                      <span className="breadcrumbSeparator">|</span>
                      <span className="span-font-roboto breadactive">
                        {"Route-Optimization"}
                        {/* {this.state.userLangs?.planning?.INDENT_PLANNING} */}
                      </span>
                    </div>
                  </>
                ) : path === "/trips" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.trip?.TRIPS_DETAILS}
                    </span>
                  </>
                ) : path === "/tripDetails" ? (
                  <>
                    <Link to="/trips">
                      <span className="breadcrumbInactive breadcrumb-active span-font-roboto">
                        {this.state.userLangs?.trip?.TRIPS_DETAILS}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.trip?.TRIP_HISTORY}
                    </span>
                  </>
                ) : path === "/Tracking" ? (
                  <>
                    <Link to="/trips">
                      <span className="breadcrumbInactive breadcrumb-active span-font-roboto ">
                        {this.state.userLangs?.trip?.TRIPS_DETAILS}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.tracking?.TRACKING}
                    </span>
                  </>
                ) : path === "/drivers" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.DRIVERS}
                    </span>
                  </>
                ) : path === "/vehicles" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.VEHICLES}
                    </span>
                  </>
                ) : path === "/vehicle-type" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.VEHICLE_TYPE}
                    </span>
                  </>
                ) : path === "/user" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.USERS}
                    </span>
                  </>
                ) : path === "/roles" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.masters?.ROLES}
                    </span>
                  </>
                ) : path === "/invoiceGeneration" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {/* Invoice Genarations */}
                      {this.state.userLangs?.invoice?.INVOICE_GENERATION}
                    </span>
                  </>
                ) : path === "/Billing/InvoiceList" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {/* Invoice Genarations */}
                      {this.state.userLangs?.invoice?.INVOICE_LIST}
                    </span>
                  </>
                ) : path === "/BillingIndex" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.invoice?.AGREEMENT}
                    </span>
                  </>
                ) : path === "/Billing/AddAgreement" ? (
                  <>
                    <Link to="/BillingIndex">
                      <span className="breadcrumbInactive breadcrumb-active span-font-roboto">
                        {this.state.userLangs?.invoice?.AGREEMENT}
                      </span>
                    </Link>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.invoice?.ADD_AGREEMENT}
                    </span>
                  </>
                ) : path === "/tripBills" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.invoice?.INVOICE_LISTS}
                    </span>
                  </>
                ) : path === "/Reports" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.TRANSPORTER_PERFORMANCE}
                    </span>
                  </>
                ) : path === "/ontime-delivery-report" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.ON_TIME_DELIVERY_REPORT1 ??
                        "On Time Delivery Report  "}
                    </span>
                  </>
                ) : path === "/vehicle-placement" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.VEHICLE_PLACEMENT_REPORT ??
                        "Vehicle Placement Report  "}
                    </span>
                  </>
                ) : path === "/indentReport-analysis" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.report?.LANE}
                    </span>
                  </>
                ) : path === "/statusIndent" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.common?.STATUS}
                    </span>
                  </>
                ) : path === "/transporterIndent" ? (
                  <>
                    <span className="span-font-roboto">
                      {this.state.userLangs?.report?.INDENT_ANALYSIS_REPORT}
                    </span>
                    <span className="breadcrumbSeparator">|</span>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.common?.TRANSPORTER}
                    </span>
                  </>
                ) : path === "/ControlTower" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.controlTower?.CONTROL_TOWER}
                    </span>
                  </>
                ) : path === "/profileSettings" ? (
                  <>
                    <span className="span-font-roboto breadactive">
                      {this.state.userLangs?.common?.PROFILE_SETTINGS}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* <div className="offlineMode">
              {navigator.onLine ? null : "Offline Mode"}
            </div> */}
            <div
              className="d-flex mt-0 pr-2"
              style={{
                padding: "24px 0px",
                alignItems: "center",
                marginTop: "0px important",
                paddingTop: "0px !important",
              }}
            >
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <Button
                  type="button"
                  color="none"
                  onClick={this.toggleFullscreen}
                  className="header-item noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i
                    className="ri-fullscreen-line"
                    style={{ color: "black" }}
                  ></i>
                </Button>
              </div>

              <ProfileMenu />
            </div>
          </div>
        </header>
        {/* </Card> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    switchsTheme: state.Layout.switchTheme,
  };
};
export default connect(mapStateToProps, { changeSwitchTheme })(HeaderNew);
