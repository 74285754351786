import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import { memo } from "react";
import {
  CloseIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
} from "../../assets/images/CommonIcons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./style.css";
const logoImage = process.env.REACT_APP_DASHBOARD_LOGO_IMAGE
  ? require("../../assets/images/" + process.env.REACT_APP_DASHBOARD_LOGO_IMAGE)
  : "";
const logoMiniImage = process.env.REACT_APP_DASHBOARD_LOGO_IMAGE
  ? require("../../assets/images/" + process.env.REACT_APP_DASHBOARD_LOGO_IMAGE)
  : "";

const productselectedicon = require("../../assets/images/navigation/productselectedicon.png");
const blogIcon = require("../../assets/images/layouts/blog.png");
const slugIcon = require("../../assets/images/layouts/slug.png");
const couponIcon = require("../../assets/images/layouts/coupon.png");
let MAIN_DASHBOARD = "MAIN_DASHBOARD";
let SLUG = "SLUG";
let BLOG = "BLOG"
let COUPON = "COUPON"
var usrInfo;

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainLink: "",
      subLink: {},
      userInfo: {},
      userLangs: undefined,
      zoomVal: "",
      closeIcon: false,
    };
  }

  componentDidMount() {
    this.resize();
    let user = localStorage.getItem("authUser");
    if (user) {
      usrInfo = JSON.parse(user);
      if (usrInfo) this.setState({ userInfo: "Admin" });
    }
  }

  resize = () => {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else {
      this.setState({ zoomVal: 0.9 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    }
  };

  componentWillUnmount(prevProps, prevState) {
    console.log(prevState, "component willunmount");
  }

  handleActiveLink = (mainLinkval) => {
    console.log(mainLinkval);

    this.props.changeActiveTheme(mainLinkval);
    this.setState(
      {
        mainLink: mainLinkval,
        show: !this.state.show,
      },
      () => {
        console.log(this.state.mainLink);
      }
    );
  };

  handleActiveSubLink = (subLink) => {
    this.setState(
      {
        subLink: subLink,
      },
      () => {
        console.log(this.state.subLink);
      }
    );
  };

  render() {
    // let { userInfo } = this.state;
    const keyboardArrowIconStyle = {
      position: "absolute",
      fontSize: 20,
      marginLeft: "10px",
      color: "#cccccc",
      right: "21%",
      marginTop: "-2%",
    };

    return (
      <>
        {!this.props.mini ? (
          <div
            id={this.props.show ? "my-sidebar-mobile" : "mySidebar"}
            className="mainExpandedStyle vertical-collpsed"
            onMouseEnter={this.props.menuBarIn}
            onMouseLeave={this.props.menuBarOut}
          >
            <div>
              <div>
                {this.props.show ? (
                  <span className="closeIconStyles">
                    <CloseIcon
                      onClick={() =>
                        this.props?.closeMenuBar(this.state.closeIcon)
                      }
                    />
                  </span>
                ) : (
                  ""
                )}
                <img
                  className="mainLogoStyleMobile"
                  src={logoImage}
                  alt="logo"
                  width="60px"
                  height="38px"
                />
              </div>
              {/* <img className="mainLogoStyle" src={logoImage} alt="logo" /> */}
            </div>
            <div className="logoSepartorStyleMobile"></div>
            <div className="linksSection">
              {/* DASHBOARD */}

              <div className="linkSection mt-2">
                <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_DASHBOARD &&
                      (this.props.location.pathname === "/product" ||
                        this.props.location.pathname === "/viewProducts")
                        ? "activeLinkSection"
                        : null
                    }`}
                  onClick={() => {
                    this.handleActiveLink(MAIN_DASHBOARD);
                  }}
                >
                  <div className="mt-2">
                    <img src={productselectedicon} alt="dashImg" />
                    <span className="mainLinkName">PRODUCTS</span>
                    {this.props.activeState === MAIN_DASHBOARD &&
                    this.state.show === true ? (
                      // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      <i style={keyboardArrowIconStyle}>
                        <KeyboardArrowUpIcon />
                      </i>
                    ) : (
                      // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      <i style={keyboardArrowIconStyle}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === MAIN_DASHBOARD && this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/product">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">Product</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="linkSection mt-2">
                <div
                  className={`mainLink ${
                    this.props.activeState === SLUG 
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(SLUG);
                  }}
                >
                  <div>
                    <img src={slugIcon} alt="dashImg" />
                    <span className="mainLinkName">SLUG</span>
                    {this.props.activeState === SLUG &&
                    this.state.show === true ? (
                      // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      <i style={keyboardArrowIconStyle}>
                        <KeyboardArrowUpIcon />
                      </i>
                    ) : (
                      // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      <i style={keyboardArrowIconStyle}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === SLUG && this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/addSlug">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">Add Slug</span>
                    </div>
                  </Link>
                  <Link to="/viewSlug">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">View Slug</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="linkSection mt-3">
                <div
                  className={`mainLink ${
                    this.props.activeState === BLOG
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(BLOG);
                  }}
                >
                  <div>
                    <img src={blogIcon} alt="dashImg" 
                    height="45px"
                    />
                    <span className="mainLinkName">BLOG</span>
                    {this.props.activeState === BLOG &&
                    this.state.show === true ? (
                      // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      <i style={keyboardArrowIconStyle}>
                        <KeyboardArrowUpIcon />
                      </i>
                    ) : (
                      // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      <i style={keyboardArrowIconStyle}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === BLOG && this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/addBlog">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">Add Blog</span>
                    </div>
                  </Link>
                  <Link to="/viewBlog">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">View Blog</span>
                    </div>
                  </Link>
                  <Link to="/addBlogSlug">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">Add Blog Slug</span>
                    </div>
                  </Link>
                  <Link to="/viewBlogSlug">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">View Blog Slug</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="linkSection mt-3">
                <div
                  className={`mainLink ${
                    this.props.activeState === COUPON
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(COUPON);
                  }}
                >
                  <div>
                    <img src={couponIcon} alt="dashImg"/>
                    <span className="mainLinkName">COUPON</span>
                    {this.props.activeState === COUPON &&
                    this.state.show === true ? (
                      // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                      <i style={keyboardArrowIconStyle}>
                        <KeyboardArrowUpIcon />
                      </i>
                    ) : (
                      // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                      <i style={keyboardArrowIconStyle}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                  </div>
                </div>
                <div
                  className={`subMenu ${
                    this.props.activeState === COUPON && this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                  }`}
                >
                  <Link to="/addCoupon">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">Add Coupon Code</span>
                    </div>
                  </Link>
                  <Link to="/viewCoupon">
                    <div className="subMenuLinkDiv">
                      <span className="subMenuLinkName">View Coupon Code</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        ) : (
          <div
            id="mySidebar"
            className="mainExpandedStyles"
            onMouseEnter={this.props.menuBarIn}
            onMouseLeave={this.props.menuBarOut}
          >
            <div>
              <img className="mainLogoStyleMini" src={logoMiniImage} alt="" />
            </div>
            <div className="logoSepartorStyle"></div>
            <div className="linksSection">
              {/* DASHBOARD */}

              <div className="linkSection mt-2">
                <div
                  className={`mainLink ${
                    this.props.activeState === MAIN_DASHBOARD &&
                    (this.props.location.pathname === "/product" ||
                      this.props.location.pathname === "/viewProducts")
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(MAIN_DASHBOARD);
                  }}
                >
                  <Link to="/dashboard">
                    <div>
                      <img
                        src={productselectedicon}
                        alt=""
                        className="imgMini"
                      />
                      <span className="mainLinkName"></span>
                      {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="linkSection mt-2">
                <div
                  className={`mainLink ${
                    this.props.activeState === SLUG
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(SLUG);
                  }}
                >
                  <Link to="/dashboard">
                    <div>
                      <img
                        src={slugIcon}
                        alt=""
                        className="imgMini"
                      />
                      <span className="mainLinkName"></span>
                      {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="linkSection mt-2">
                <div
                  className={`mainLink ${
                    this.props.activeState === BLOG
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(BLOG);
                  }}
                >
                  <Link to="">
                    <div>
                      <img
                        src={blogIcon}
                        alt=""
                        className="imgMini"
                      />
                      <span className="mainLinkName"></span>
                      {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="linkSection mt-2">
                <div
                  className={`mainLink ${
                    this.props.activeState === COUPON 
                      ? "activeLinkSection"
                      : null
                  }`}
                  onClick={() => {
                    this.handleActiveLink(COUPON);
                  }}
                >
                  <Link to="">
                    <div>
                      <img
                        src={couponIcon}
                        alt=""
                        className="imgMini"
                        height="40%"
                      />
                      <span className="mainLinkName"></span>
                      {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                    </div>
                  </Link>
                </div>
              </div>

            </div>
            <div></div>
          </div>
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    activeState: state.Layout.activeTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTheme: (name) => {
      dispatch({ type: "CHANGE_ACTIVE_THEME", payload: name });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(index));
