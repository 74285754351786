// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  getApiNoToken,
  postApiNoToken,
} from "../../helpers/auth_helper";

function* workfetchProducts({ offset,filter, callback }) {
  let filterName = filter?filter?.name:"";
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/products?page=${offset}&name=${filterName}`
    const response = yield call(getApiNoToken, uri);
    
    callback(response);
    yield put({
      type: types.FETCH_PRODUCTS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch dealer.", error);
  }
}

function* workupdateProducts({ values , callback }) {
  try {
    // Try to call the API
    const uri =  process.env.REACT_APP_SERVERURL +`/updateProduct`;
    const response = yield call(postApiNoToken, uri, values);
    console.log(response,"saga")
    yield put({
      type: types.UPDATE_PRODUCTS_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }  
}

 function* workfilterProducts({ searchParam , callback }){
  try {
    // Try to call the API
    const uri =  process.env.REACT_APP_SERVERURL +`/getProducts`;
    const response = yield call(postApiNoToken, uri ,searchParam);
    console.log('saga',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FILTER_PRODUCTS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not FILTER.", error);
  }
  }

/**
 * Watchers
 */

export function* watchFetchProducts() {
  yield takeEvery(types.FETCH_PRODUCTS, workfetchProducts);
}

export function* watchUpdateProducts() {
  yield takeEvery(types.UPDATE_PRODUCTS, workupdateProducts);
}

export function* watchFilterProducts() {
  yield takeEvery(types.FILTER_PRODUCTS, workfilterProducts);
}

function* ProductSaga() {
  yield all([
    fork(watchFetchProducts),
    fork(watchUpdateProducts),
    fork(watchFilterProducts),
  ]);
}

export default ProductSaga;
