import React, { Component } from "react";
import Select from "react-select";
import moment from "moment";
import { brandList, addCoupon } from "../../store/coupon/actions";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { logoutUserSuccess } from "../../store/auth/actions";
import Datetime from "react-datetime";
import { Link } from "react-router-dom";
// import jwt_decode from "jwt-decode";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  CardBody,
  Card,
} from "reactstrap";

class AddCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rootCategory: "",
      description:"",
      price: "",
      selectedGroup: {},
      discount: "",
      optionBrandList: [],
      startDate: "",
      endDate: "",
      couponCode: "",
      errors: {},
    };
  }
  onChangeInput = (name, val) => {
    this.setState(
      {
        [name]: val,
      },
      () => {}
    );
  };

  validateValAdd = () => {
    const errorsAdd = {};

    const {
      rootCategory,
      price,
      discount,
      selectedGroup,
      couponCode,
      startDate,
      endDate,
      description
    } = this.state;
    var time1 = moment(startDate).format("YYYY-MM-DD");
    var time2 = moment(endDate).format("YYYY-MM-DD");
    if (!rootCategory) {
      errorsAdd.rootCategory = "Category is required !";
    }
    if (!price) {
      errorsAdd.price = "Price is required !";
    }
    if (!Object.keys(selectedGroup).length) {
      errorsAdd.selectedGroup = "Brand is required !";
    }
    if (!discount) {
      errorsAdd.discount = "Discount is required!";
    }
    if (!couponCode) {
      errorsAdd.couponCode = "Coupon Code is required !";
    }
    if (!startDate) {
      errorsAdd.startDate = "Start Date is required !";
    }
    if (!description) {
      errorsAdd.description = "Description is required !";
    }
    if (!endDate) {
      errorsAdd.endDate = "End Date is required !";
    } else if (!startDate) {
      errorsAdd.endDate = "Start Date is required first!!";
    } else if (!(time2 > time1)) {
      errorsAdd.endDate = "End Date must be Greater than StartDate !";
    }

    return errorsAdd;
  };

  handleSubmit = () => {
    var tokenAuth = JSON.parse(window.localStorage.getItem("token"))
      ? JSON.parse(window.localStorage.getItem("token"))
      : "";
    // var decodedToken = jwt_decode(tokenAuth);
    // if (decodedToken.exp < new Date() / 1000) {
    //   console.log("EXPIRED");
    //   this.props.logoutUserSuccess();
    // }
    const {
      rootCategory,
      price,
      discount,
      selectedGroup,
      couponCode,
      startDate,
      endDate,
      description
    } = this.state;
    this.setState({ errors: this.validateValAdd() }, () => {
      if (Object.keys(this.state.errors).length === 0) {
        if (this.state.rootCategory) {
          let dataRaw = {
            categories: [
              {
                categoryName: rootCategory?.label,
              },
            ],
            brands: [
              {
                brandName: selectedGroup?.label,
              },
            ],
            code: couponCode,
            description:description,
            archived: false,
            discountInPercentage: discount,
            price: price,
            validFrom: startDate,
            validTill: endDate,
            userAcessToken: tokenAuth,
          };

          this.props.addCoupon(dataRaw, (res) => {
            if (res?.code === 200 || res?.code === 201) {
              NotificationManager.success(
                "Coupon Created Successfully",
                "Success"
              );
              this.props.history.push("/viewCoupon");
            } else {
              console.log(res?.code);
              NotificationManager.error(res["message"], "Error");
            }
          });
        }
      } else {
        NotificationManager.error("Required All fields", "Error");
      }
    });
  };

  reset = () => {
    this.setState({
      rootCategory: "",
      price: "",
      discount: "",
      selectedGroup: "",
      couponCode: "",
      description:"",
      startDate: "",
      endDate: "",
      errors: {},
    });
  };

  handleSelectBlogCatGroup = (selectBlogCayegory) => {
    this.setState({ rootCategory: selectBlogCayegory }, () => {
      if (this.state.rootCategory) {
        let cat = this.state?.rootCategory?.value;
        let dataRaw = {
          numberOfRecord: 10,
          pageNumber: 1,
          rootCategoryDHybrid: cat,
        };
        this.props.brandList(dataRaw, (res) => {
          console.log(res, "rae");
          let brandList = res?.response?.body?.productBrandList;
          if (brandList?.length) {
            this.optionGroup(brandList);
          }
        });
      }
    });
  };
  optionGroup = (brandList) => {
    let optionBrandArr = [];

    if (brandList && brandList.length > 0) {
      brandList.map((brandOption) => {
        optionBrandArr.push({
          label: brandOption?.brandName,
          value: brandOption?.brandName,
        });
        return true;
      });
    }
    this.setState({ optionBrandList: optionBrandArr });
  };
  handleSelectBrandCatGroup = (selectedGroup) => {
    this.setState({ selectedGroup }, () => {});
  };
  render() {
    // loading, rows, selectedGroup,
    const yesterday = moment().subtract(1, "day");
    const disablePastDt = (current) => {
      return current.isAfter(yesterday);
    };
    const { optionBrandList, selectedGroup, errors } = this.state;
    const blogCategoryOption = [
      { label: "Grooming", value: "Grooming" },
      { label: "Fashion", value: "Fashion" },
    ];
    return (
      <React.Fragment>
        <Container fluid={true} className="mt-2">
          <Row>
            <Col xl="12" className="p-1">
              <Card>
                <CardBody style={{ padding: "2%" }}>
                  <Grid
                    className="breadcrumb p-0"
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={6} md={8}>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <i className="fa fa-tag fa-sm"></i>{" "}
                          <Link to="viewCoupon">Coupon</Link>
                        </li>
                        <li className="breadcrumb-item active">Add Coupon</li>
                      </ol>
                    </Grid>
                  </Grid>
                  <Form>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Coupon Code
                          </Label>
                          <Input
                            name="couponCode"
                            placeholder="Coupon Code"
                            type="text"
                            value={this.state.couponCode}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("couponCode", e.target.value)
                            }
                          />
                          {errors?.couponCode && (
                            <div className="error">{errors?.couponCode} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Description
                          </Label>
                          <Input
                            name="description"
                            placeholder="Description"
                            type="textarea"
                            rows="3"
                            value={this.state.description}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("description", e.target.value)
                            }
                          />
                          {errors?.description && (
                            <div className="error">{errors?.description} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Category
                          </Label>
                          <Select
                            className="form-control-xs"
                            value={this.state.rootCategory}
                            onChange={this.handleSelectBlogCatGroup}
                            options={blogCategoryOption}
                            classNamePrefix="select2-selection"
                          />
                          {errors?.rootCategory && (
                            <div className="error">{errors?.rootCategory} </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Brand
                          </Label>
                          <Select
                            className="form-control-xs"
                            value={selectedGroup}
                            onChange={this.handleSelectBrandCatGroup}
                            options={optionBrandList}
                            classNamePrefix="select2-selection"
                          />
                          {errors?.selectedGroup && (
                            <div className="error">
                              {errors?.selectedGroup}{" "}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Price
                          </Label>
                          <Input
                            name="price"
                            placeholder="Price"
                            className="form-control-lg"
                            type="number"
                            value={this.state.price}
                            id="validationCustom03"
                            onChange={(e) =>
                              this.onChangeInput("price", e.target.value)
                            }
                          />
                          {errors?.price && (
                            <div className="error">{errors?.price} </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Discount
                          </Label>
                          <Input
                            name="discount"
                            placeholder="Discount"
                            type="text"
                            value={this.state.discount}
                            className="form-control-lg"
                            timeFormat={false}
                            onChange={(e) =>
                              this.onChangeInput("discount", e.target.value)
                            }
                          />
                          {errors?.discount && (
                            <div className="error">{errors?.discount} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Valid Start Date
                          </Label>
                          <Datetime
                            name="startDate"
                            value={
                              this.state.startDate
                                ? this.state.endDate
                                : " DD/MM/YYYY"
                            }
                            isValidDate={disablePastDt}
                            placeholderText={"Please select a date"}
                            onChange={(e) => {
                              this.onChangeInput(
                                "startDate",
                                e.format("YYYY-MM-DD")
                              );
                            }}
                            dateFormat={"DD/MM/YYYY"}
                            timeFormat={false}
                          />
                          {errors?.startDate && (
                            <div className="error">{errors?.startDate} </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Valid End Date
                          </Label>

                          <Datetime
                            name="endDate"
                            value={
                              this.state.endDate
                                ? this.state.endDate
                                : "DD/MM/YYYY"
                            }
                            isValidDate={disablePastDt}
                            placeholderText={"Please select a date"}
                            onChange={(e) => {
                              this.onChangeInput(
                                "endDate",
                                e.format("YYYY-MM-DD")
                              );
                            }}
                            dateFormat={"DD/MM/YYYY"}
                            timeFormat={false}
                          />
                          {errors?.endDate && (
                            <div className="error">{errors?.endDate} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button
                      color="primary"
                      // type="submit"
                      className="mt-4 mr-3"
                      onClick={this.handleSubmit}
                    >
                      Add
                    </Button>
                    <Button
                      color="success"
                      // type="submit"
                      className="mt-4"
                      onClick={this.reset}
                    >
                      RESET
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(null, { brandList, addCoupon, logoutUserSuccess })(
  AddCoupon
);
