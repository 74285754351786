//PRODUCT SLUG

import { types } from "./actionTypes";
export const fetchSlug = (category,callback) => ({
  type: types.FETCH_SLUG,
  category,
  callback,
});

export const fetchSlugList = (offset,callback) => ({
  type: types.FETCH_SLUG_LIST,
  offset,
  callback,
});

//update slug by product's category
export const updateSlug = (Id, values , callback ) => ({
  type: types.UPDATE_SLUG,
  Id,
  values,
  callback
});

//update slug list
export const updateSlugList = ( values , callback ) => ({
  type: types.EDIT_SLUG_LIST,
  values,
  callback
});

export const deleteSlugList = ( id , callback ) => ({
  type: types.DELETE_SLUG_LIST,
  id,
  callback
});

export const addSlug = (values , callback ) => ({
  type: types.CREATE_SLUG,
  values,
  callback
});