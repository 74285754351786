import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import { MoonLoader } from "react-spinners";
import { connect } from "react-redux";
import CouponTable from "./CouponTable";
import { Grid } from "@material-ui/core";
import { fetchCoupon ,deleteCoupon} from "../../store/coupon/actions";
import moment from "moment";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Pagination from "../Common/PaginateData";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 1;
class Coupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      slugName: "",
      rows: [],
    };
  }
  componentDidMount = () => {
    this.getAllData();
  };
  resetCoupon = () => {
    offset = 1;
    this.getAllData();
  };
  getAllData = () => {
    let rawData = {
      numberOfRecord: 10,
      pageNumber: offset,
    };
    this.props.fetchCoupon(rawData, (CouponList) => {
      if (CouponList) {
        let rows = [];
        let couponLists = CouponList?.response?.body?.couponApiResponseList;
        let count = CouponList?.response?.body?.count;
        totCnt = count;
        fromVal = totCnt !== 0 ? (offset - 1) * 10 + 1 : fromVal;
        toVal = (offset - 1) * 10 + couponLists?.length;
        totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
        couponLists.forEach((ele) => {
          rows.push({
            code: ele.code,
            category: ele?.categories
              ? ele.categories.map((catDetail) => {
                  return catDetail?.categoryName;
                })
              : "N/A",
            price: ele.price,
            discountInPercentage: ele?.discountInPercentage
              ? ele?.discountInPercentage
              : "N/A",
            brand: ele?.brands
              ? ele.brands.map((brandName) => {
                  return brandName?.brandName;
                })
              : "N/A",
            slug: ele?.brands
              ? ele.brands.map((brandName) => {
                  return brandName?.slug[0]?.slugName;
                })
              : "N/A",
            startDate: ele?.validFrom ? moment(ele?.validFrom).format("DD/MM/YYYY") : "N/A",
            endDate: ele?.validTill ? moment(ele?.validTill).format("DD/MM/YYYY") : "N/A",
            actions: (
              <div className="d-flex align-items-center tableBtns ">
                <Button
                  outline
                  color="secondary"
                  size="md"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/UpdateCoupon",
                      coupon: ele,
                      mode: "edit",
                      couponID: ele?.id,
                    });
                  }}
                >
                  Edit
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  outline
                  color="secondary"
                  size="md"
                  onClick={() => {
                    this.deleteoupon(ele?.id);
                  }}
                >
                  Delete
                </Button>{" "}
                &nbsp;&nbsp;
              </div>
            ),
          });
        });
        this.setState({ rows: rows, loading: false });
      }
    });
  };
  handleChangePage = (event, val) => {
    this.setState({ loading: true });
    offset = val;
    this.getAllData();
  };

  deleteoupon = (id)=>{
    this.props.deleteCoupon(id ,(res)=>{
      if (res) {
        NotificationManager.success("Coupon Deleted Successfully", "Success");
        this.props.history.push("/viewCoupon");
        this.getAllData();
      } else {
        NotificationManager.error("error", "Error");
      }
    })
  }
  render() {
    const { loading, rows } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    return (
      <div className="mt-1">
        <Container fluid>
          <Row>
            <Col xs={12} className="p-1">
              <Card>
                <CardBody>
                  <Grid
                    className="breadcrumb p-0"
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={6} md={8}>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <i className="fa fa-tag fa-sm"></i> Coupon
                        </li>
                        <li className="breadcrumb-item active">Coupon List</li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <div className="d-flex justify-content-center flex-fill">
                        <div className="p-2">
                          <Link to="/addCoupon">
                            <Button size="sm" color="primary">
                              Add Coupon
                            </Button>
                          </Link>
                        </div>
                        <div className="p-2">
                          <Button
                            size="sm"
                            color="success"
                            onClick={this.resetCoupon}
                          >
                            RESET
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <CouponTable rows={rows ? rows : []} />
                  <Pagination
                    fromVal={fromVal}
                    toVal={toVal}
                    totCnt={totCnt}
                    offset={offset}
                    totPages={totPages}
                    handleChangePage={this.handleChangePage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    CouponData: state !== undefined?.Coupon?.coupon?.couponList?.response,
  };
};

export default connect(mapStateToProps, { fetchCoupon ,deleteCoupon})(Coupon);
