// import { mapKeys } from 'lodash';
import { types } from "../actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_SLUG_SUCCESS:
      return { slugList: action.payload };

      case types.FETCH_SLUG_LIST_SUCCESS:
        return { allSlugList: action.payload };
    //update slug by product's category
    case types.UPDATE_SLUG_SUCCESS:
      return { updatedSlug: action.payload };

    //update slug
    case types.EDIT_SLUG_LIST_SUCCESS:
      return { editSlugList: action.payload };

    case types.DELETE_SLUG_LIST_SUCCESS:
      return { deleteSlugList: action.payload };

    case types.CREATE_SLUG_SUCCESS:
      return { updatedSlug: action.payload };
    default:
      state = { ...state };
      break;
  }

  return state;
};
