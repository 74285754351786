import React, { Component } from "react";
import { MDBModal, MDBModalBody, MDBContainer, Card } from "mdbreact";
import CloseIcon from "@mui/icons-material/Close";
export default class ProductImageView extends Component {
  render() {
    const modalStyle = {
      height: "100%",
      width: "100%",
      backgroundColor: "#ffffff",
      paddingTop: "2px",
      paddingLeft: "2px",
      paddingRight: "2px",
      paddingBottom: "auto",
      position: "relative",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      color: "#2800fc",
      width: "20px",
      marginRight: "0px",
    };
    return (
      <React.Fragment>
        <MDBContainer className="text-capitalize viewModel">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggleModel()}
            size="md"
            backdrop={true}
          >
            <MDBModalBody>
              <Card style={modalStyle}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={headerStyle}>Preview</span>

                  <CloseIcon
                    style={closeStyle}
                    onClick={() => this.props.toggleModel()}
                  />
                </div>
                <div className="mt-0 p-0  product-img">
                  <img
                    src={this.props?.image}
                    alt=""
                    className="img-fluid mx-auto d-block tab-img rounded"
                  ></img>
                </div>
              </Card>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}
