import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import QuickFilters from "./QuickFilters";
import { MoonLoader } from "react-spinners";
import { fetchProducts } from "../../store/products/actions";
import { connect } from "react-redux";
import Pagination from "../Common/PaginateData";
import ProductTable from "./ProductTable";
import ProductImageView from "./ProductImageView";
import { NotificationManager } from "react-notifications";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 1;

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      viewImageModel: false,
      currImage: "",
      slug: [],
      rows: [],
      search: "",
    };
  }

  componentDidMount = () => {
    this.getAllData();
  };
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 1;
  };

  getAllData = () => {
    this.setState({ loading: true });
    this.props.fetchProducts(offset - 1, { name: this.state.search }, (res) => {
      console.log(res, "products");
      this.refineData(res);
    });
  };
  viewImage = (image) => {
    this.setState({ viewImageModel: !this.state.viewImageModel }, () => {
      this.setState({ currImage: image });
    });
  };
  viewData = (productData, mode) => {
    let slugArr = [];
    let slug = productData?.slug
      ? productData.slug.map((element) => {
          slugArr.push({
            label: element?.slugName,
            value: element?.slugId,
          });
          return true;
        })
      : "N/A";
    console.log(slug);
    if (productData) {
      window.history.replaceState(null, null, "/");
      let cat = productData["Root Category[dHybrid]"]
        ? productData["Root Category[dHybrid]"]
        : "N/A";
      let data = {};
      data.SKU = productData?.SkuName;
      data.skuCode = productData?.SkuCode;
      data.vendor = productData["Vendor Name"]
        ? productData["Vendor Name"]
        : "";
      data.slug = slugArr ? slugArr : "";
      data.category = cat;
      data.salePrice = productData ? productData.SalePrice : "N/A";
      data.size = productData["dHybrid Size"]
        ? productData["dHybrid Size"]
        : "N/A";
      data.image = productData?.Image1 ? productData?.Image1 : "N/A";
      data.quantity =
        productData?.inventoryQuantity && productData.inventoryQuantity != null
          ? productData?.inventoryQuantity
          : "N/A";
      this.props.history.push({
        pathname: "/viewProducts",
        productById: data,
        mode: mode,
        productId: productData?.id ? productData?.id : "",
      });
    }
  };

  skuLimitName = (SkuName) => {
    return SkuName?.length > 50 ? SkuName.slice(0, 45) + "..." : SkuName;
  };

  refineData(res) {
    let rowpro = [];
    let slug;
    if (res && res?.response?.body?.code === 200) {
      let products = res.response.body.products;

      totCnt = res?.response?.body?.totalItems;
      fromVal = totCnt !== 0 ? (offset - 1) * 10 + 1 : fromVal;
      toVal = (offset - 1) * 10 + products.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);

      products.forEach((element, index, array) => {
        //  console.log(element.Image1,"im")

        slug = element.slug
          ? element.slug.map((element) => {
              return <div>{element?.slugName}</div>;
            })
          : "N/A";

        if (element) {
          rowpro.push({
            // ProductName:element.SkuName?element.SkuName:"N/A",
            ProductName: this.skuLimitName(element?.SkuName),
            ProductCode: element?.SkuCode,
            ProductImage: element.Image1 ? (
              <div style={{ cursor: "pointer" }}>
                <img
                  src={element.Image1}
                  width="82px"
                  height="70px"
                  alt=""
                  onClick={() => {
                    this.viewImage(element.Image1);
                  }}
                ></img>
              </div>
            ) : (
              "N/A"
            ),
            category: element["Root Category[dHybrid]"]
              ? element["Root Category[dHybrid]"]
              : "N/A",
            slug: slug?.length ? slug : "N/A",
            salePrice: element ? element.SalePrice : "N/A",
            brand: element["Brand Name"] ? element["Brand Name"] : "N/A",
            actions: (
              <div className="d-flex align-items-center tableBtns ">
                <Button
                  outline
                  color="secondary"
                  size="md"
                  onClick={() => {
                    this.viewData(element, "view");
                  }}
                >
                  View
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  outline
                  color="secondary"
                  size="md"
                  onClick={() => {
                    this.viewData(element, "edit");
                  }}
                >
                  Edit
                </Button>{" "}
                &nbsp;&nbsp;
              </div>
            ),
          });
        }
      });
      this.setState({ rows: rowpro, loading: false });
    } else {
      this.setState({ loading: false }, () => {
        NotificationManager.error(res?.error, "Error");
      });
    }
  }

  reset = () => {
    this.setState({ search: "" }, () => {
      offset = 1;
      this.getAllData();
    });
  };
  //filter
  filterAssigner = (e, filter) => {
    this.setState({ [filter]: e.target.value }, () => {
      if (this.state.search.length > 0) {
        offset = 1;
      }
    });
  };

  enterCheck = (event) => {
    if (event.key === "Enter") this.filterList();
  };

  filterList = () => {
    this.getAllData();
  };

  handleChangePage = (event, val) => {
    this.setState({ loading: true });

    offset = val;
    this.getAllData();
  };

  render() {
    const { loading, rows } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="mt-1">
          <Container fluid>
            <Row>
              <Col xs={12} className="p-1">
                <Card>
                  <CardBody>
                    <QuickFilters
                      enterCheck={this.enterCheck}
                      filterAssigner={this.filterAssigner}
                      filterList={this.filterList}
                      productSearchInput={this.state.search}
                      reset={this.reset}
                    />

                    <ProductTable rows={rows ? rows : []} />
                    <Pagination
                      fromVal={fromVal}
                      toVal={toVal}
                      totCnt={totCnt}
                      offset={offset}
                      totPages={totPages}
                      handleChangePage={this.handleChangePage}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ProductImageView
          isOpen={this.state.viewImageModel}
          toggleModel={this.viewImage}
          image={this.state.currImage}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  orders: state.Product.product,
});

export default connect(mapStateToProps, {
  fetchProducts,
})(Product);
