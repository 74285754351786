import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import  Error401 from "../pages/Utility/Error401"


//Tables

import Product from "../pages/Product/Product";
import Coupon from "../pages/Coupon/Coupon";
import Slug from "../pages/Slug/Slug";
import Blog from "../pages/Blog/Blog";
//forms
import viewProduct from "../pages/Product/viewProduct";
import AddCoupon from "../pages/Coupon/AddCoupon";
import AddSlug from "../pages/Slug/AddSlug";
import AddBlog from "../pages/Blog/AddBlog";
import UpdateCoupon from "../pages/Coupon/UpdateCoupon";
import AddBlogSlug from "../pages/BlogSlug/AddBlogSlug";
import ViewBlogSlug from "../pages/BlogSlug/ViewBlogSlug";
import EditBlogSlug from "../pages/BlogSlug/EditBlogSlug";



const authProtectedRoutes = [
 
  { path: "/product", component: Product },
  { path: "/viewProducts", component: viewProduct },

  { path: "/addSlug", component: AddSlug },
  { path: "/viewSlug", component: Slug },

  { path: "/addBlog", component: AddBlog },
  { path: "/viewBlog", component: Blog },
  { path: "/addBlogSlug", component: AddBlogSlug },
  { path: "/viewBlogSlug", component: ViewBlogSlug },
  { path: "/editBlogSlug", component: EditBlogSlug },



  { path: "/addCoupon", component: AddCoupon },
  { path: "/updateCoupon", component: UpdateCoupon },
  { path: "/viewCoupon", component: Coupon },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/viewBlog" />,
  },
];
const publicRoutes = [
 
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/pages-401", component: Error401 },

 
 

];

export { authProtectedRoutes, publicRoutes };
