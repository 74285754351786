
export const types = {

  AUTH_LOGIN:"AUTH_LOGIN",
  AUTH_LOGIN_SUCCESS:"AUTH_LOGIN_SUCCESS",

  LOGOUT_USER:"LOGOUT_USER",
  LOGOUT_USER_SUCCESS:"LOGOUT_USER_SUCCESS"


};
