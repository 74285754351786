// @flow
import {
  all,
  call,
  fork,
  takeEvery,
  put,
  // putResolve,
} from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  getApiNoToken,
  // putApi,
  postApi,
  postApiNoToken,
  postUploadApi,
  deleteApi,
  putApiAuth,
} from "../../helpers/auth_helper";

function* workfetchBlogs({ offset, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/getBlogs`;
    const response = yield call(postApiNoToken, uri, offset);

    callback(response);
    yield put({
      type: types.FETCH_BLOG_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch dealer.", error);
  }
}

function* workfetchTag({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/getTags`;
    const response = yield call(getApiNoToken, uri);
    console.log(response, "saga");
    callback(response);
    yield put({
      type: types.FETCH_TAG_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workcreateBlog({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/createBlogs`;
    const response = yield call(postApi, uri, values);
    console.log(response, "saga");
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_BLOG_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not FILTER.", error);
  }
}

function* workupdateBlogs({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/updateBlog`;
    const response = yield call(putApiAuth, uri, values);
    console.log(response, "saga");
    callback(response);
    yield put({
      type: types.UPDATE_BLOG_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}
function* workblogCatories({ data, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/blogSlugList/${data}`;
    const response = yield call(getApiNoToken, uri);
    console.log(response, "saga");
    callback(response);
    yield put({
      type: types.GET_BLOG_CATEGORIES_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workdeleteBlog({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/deleteBlogById/${id}`;
    const response = yield call(deleteApi, uri);
    console.log(response, "saga");
    callback(response);
    yield put({
      type: types.DELETE_BLOG_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workuploadBlogImage({ data, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/uploadFile`;
    const response = yield call(postUploadApi, uri, data);
    console.log(response, "saga");
    callback(response);
    yield put({
      type: types.UPLOAD_BLOG_IMAGE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workfetchBlogSlug({ offset , callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/blogSlugList?page=${offset}&size=10`;
    const response = yield call(getApiNoToken, uri);
    console.log(response, "saga");
    callback(response);
    yield put({
      type: types.FETCH_BLOGSLUG_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workaddBlogSlug({ values, callback }) {
  console.log(values, "saga");
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/createBlogSlug`;
    const response = yield call(postApiNoToken, uri, values);
    console.log(response, "saga");
    callback(response);
    yield put({
      type: types.ADD_BLOGSLUG_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workeditBlogSlug({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/updateBlogSlug`;
    const response = yield call(postApiNoToken, uri, values);
    console.log(response, "saga");
    callback(response);
    yield put({
      type: types.EDIT_BLOGSLUG_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

function* workdeleteBlogSlug({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/deleteBlogSlug/${id}`;
    const response = yield call(deleteApi, uri);
    console.log(response, "saga");
    callback(response);
    yield put({
      type: types.DELETE_BLOGSLUG_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch .", error);
  }
}

/**
 * Watchers
 */

export function* watchFetchBlog() {
  yield takeEvery(types.FETCH_BLOG, workfetchBlogs);
}

export function* watchUpdateBlog() {
  yield takeEvery(types.UPDATE_BLOG, workupdateBlogs);
}

export function* watchCreateBlog() {
  yield takeEvery(types.CREATE_BLOG, workcreateBlog);
}

export function* watchDeleteBlog() {
  yield takeEvery(types.DELETE_BLOG, workdeleteBlog);
}

export function* watchBlogCategories() {
  yield takeEvery(types.GET_BLOG_CATEGORIES, workblogCatories);
}

export function* watchFetchTag() {
  yield takeEvery(types.FETCH_TAG, workfetchTag);
}

export function* watchUploadBlogImage() {
  yield takeEvery(types.UPLOAD_BLOG_IMAGE, workuploadBlogImage);
}

export function* watchFetchBlogSlug() {
  yield takeEvery(types.FETCH_BLOGSLUG_LIST, workfetchBlogSlug);
}

export function* watchAddBlogSlug() {
  yield takeEvery(types.ADD_BLOGSLUG_LIST, workaddBlogSlug);
}
export function* watchEditBlogSlug() {
  yield takeEvery(types.EDIT_BLOGSLUG_LIST, workeditBlogSlug);
}
export function* watchDeleteBlogSlug() {
  yield takeEvery(types.DELETE_BLOGSLUG_LIST, workdeleteBlogSlug);
}

function* BlogSaga() {
  yield all([
    fork(watchFetchBlog),
    fork(watchCreateBlog),
    fork(watchUpdateBlog),
    fork(watchDeleteBlog),
    fork(watchBlogCategories),

    fork(watchFetchTag),
    fork(watchUploadBlogImage),

    fork(watchFetchBlogSlug),
    fork(watchAddBlogSlug),
    fork(watchEditBlogSlug),
    fork(watchDeleteBlogSlug),
  ]);
}

export default BlogSaga;
