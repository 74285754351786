import React, { Component } from "react";
import Select from "react-select";
// import jwt_decode from "jwt-decode";
import { brandList, updateCoupon } from "../../store/coupon/actions";
import { logoutUserSuccess } from "../../store/auth/actions";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  CardBody,
  Card,
} from "reactstrap";

class UpdateCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rootCategory: "",
      price: "",
      selectedGroup: "",
      discount: "",
      description:"",
      optionBrandList: [],
      startDate: "",
      endDate: "",
      couponCode: "",
      id: "",
      errors: {},
    };
  }
  onChangeInput = (name, val) => {
    this.setState(
      {
        [name]: val,
      },
      () => {}
    );
  };

  componentDidMount = () => {
    if (this.props.history?.location?.coupon) {
      let rootCategory = {};
      let brandNameOj = {};
      this.setState({ mode: "edit" }, () => {
        if (this.state.mode === "edit") {
          let data = this.props.history?.location?.coupon;
          data.categories.map((res) => {
            rootCategory.label = res?.categoryName;
            rootCategory.value = res?.categoryName;
            return true;
          });
          data.brands.map((res) => {
            brandNameOj.label = res?.brandName;
            brandNameOj.value = res?.brandName;
            return true;
          });

          this.setState(
            {
              rootCategory: rootCategory,
              price: data?.price,
              selectedGroup: brandNameOj,
              discount: data?.discountInPercentage,
              startDate: data?.validFrom
                ? moment(data?.validFrom).format("YYYY-MM-DD")
                : "",
              endDate: data?.validTill
                ? moment(data?.validTill).format("YYYY-MM-DD")
                : "",
              couponCode: data?.code,
              id: data?.id,
              description:data?.description?data?.description:""
            },
            () => {
              if (this.state.rootCategory) {
                let cat = this.state?.rootCategory?.value;
                let dataRaw = {
                  numberOfRecord: 10,
                  pageNumber: 1,
                  rootCategoryDHybrid: cat,
                };
                this.props.brandList(dataRaw, (res) => {
                  let brandList = res?.response?.body?.productBrandList;
                  if (brandList?.length) {
                    this.optionGroup(brandList);
                  }
                });
              }
            }
          );
        }
      });
    }
  };
  validateValAdd = () => {
    const errorsAdd = {};

    const {
      startDate,
      endDate,
    } = this.state;
    var time1 = moment(startDate).format("YYYY-MM-DD");
    var time2 = moment(endDate).format("YYYY-MM-DD");
  
    if (!startDate) {
      errorsAdd.startDate = "Start Date is required !";
    }
    if (!endDate) {
      errorsAdd.endDate = "End Date is required !";
    } else if (!startDate) {
      errorsAdd.endDate = "Start Date is required first!!";
    } else if (!(time2 > time1)) {
      errorsAdd.endDate = "End Date must be Greater than StartDate !";
    }

    return errorsAdd;
  };

  handleSubmit = () => {
    var tokenAuth = JSON.parse(window.localStorage.getItem("token"))
      ? JSON.parse(window.localStorage.getItem("token"))
      : "";

    const {
      rootCategory,
      price,
      discount,
      description,
      selectedGroup,
      couponCode,
      startDate,
      endDate,
      id,
    } = this.state;
    this.setState({ errors: this.validateValAdd() }, () => {
    if (Object.keys(this.state.errors).length === 0) {
    if (this.state.rootCategory) {
      let dataRaw = {
        id: id,
        categories: [
          {
            categoryName: rootCategory?.label,
          },
        ],
        brands: [
          {
            brandName: selectedGroup?.label,
          },
        ],
        code: couponCode,
        archived: false,
        description:description,
        discountInPercentage: discount ? discount : "",
        price: price ? price : "",
        validFrom: startDate ? startDate : "",
        validTill: endDate ? endDate : "",
        userAcessToken: tokenAuth,
      };
      this.props.updateCoupon(dataRaw, (res) => {
        if (res?.code === 200) {
          NotificationManager.success("Coupon Updated Successfully", "Success");
          this.props.history.push("/viewCoupon");
        } else {
          NotificationManager.error(res?.["message"], "Error");
        }
      });
    }
  } else {
    NotificationManager.error("Required All fields", "Error");
  }
});
  };

  handleSelectBlogCatGroup = (selectBlogCayegory) => {
    this.setState({ rootCategory: selectBlogCayegory }, () => {
      if (this.state.rootCategory) {
        let cat = this.state?.rootCategory?.value;
        let dataRaw = {
          numberOfRecord: 10,
          pageNumber: 1,
          rootCategoryDHybrid: cat,
        };
        this.props.brandList(dataRaw, (res) => {
          console.log(res, "rae");
          let brandList = res?.response?.body?.productBrandList;
          if (brandList?.length) {
            this.optionGroup(brandList);
          }
        });
      }
    });
  };
  optionGroup = (brandList) => {
    let optionBrandArr = [];

    if (brandList && brandList.length > 0) {
      brandList.map((brandOption) => {
        optionBrandArr.push({
          label: brandOption?.brandName,
          value: brandOption?.brandName,
        });
        return true;
      });
    }
    this.setState({ optionBrandList: optionBrandArr });
  };
  handleSelectBrandCatGroup = (selectedGroup) => {
    this.setState({ selectedGroup }, () => {});
  };

  render() {
    const yesterday = moment().subtract(1, "day");
    const disablePastDt = (current) => {
      return current.isAfter(yesterday);
    };
    const { optionBrandList, selectedGroup ,errors} = this.state;
    const blogCategoryOption = [
      { label: "Grooming", value: "Grooming" },
      { label: "Fashion", value: "Fashion" },
    ];
    return (
      <React.Fragment>
        <Container fluid={true} className="mt-2">
          <Row>
            <Col xl="12" className="p-1">
              <Card>
                <CardBody style={{ padding: "2%" }}>
                  <Grid
                    className="breadcrumb p-0"
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={6} md={8}>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <i className="fa fa-tag fa-sm"></i>{" "}
                          <Link to="viewCoupon">Coupon</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          Update Coupon
                        </li>
                      </ol>
                    </Grid>
                  </Grid>
                  <Form>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Coupon Code
                          </Label>
                          <Input
                            name="couponCode"
                            placeholder="Coupon Code"
                            type="text"
                            value={this.state.couponCode}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("couponCode", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6"></Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Description
                          </Label>
                          <Input
                            name="description"
                            placeholder="Description"
                            type="textarea"
                            rows="3"
                            value={this.state.description}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("description", e.target.value)
                            }
                          />
                          {errors?.description && (
                            <div className="error">{errors?.description} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Category
                          </Label>
                          <Select
                            className="form-control-xs"
                            value={this.state.rootCategory}
                            onChange={this.handleSelectBlogCatGroup}
                            options={blogCategoryOption}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Brand
                          </Label>
                          <Select
                            className="form-control-xs"
                            value={selectedGroup}
                            onChange={this.handleSelectBrandCatGroup}
                            options={optionBrandList}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Price
                          </Label>
                          <Input
                            name="price"
                            placeholder="Price"
                            className="form-control-lg"
                            type="number"
                            value={this.state.price}
                            id="validationCustom03"
                            onChange={(e) =>
                              this.onChangeInput("price", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Discount
                          </Label>
                          <Input
                            name="discount"
                            placeholder="Discount"
                            type="text"
                            value={this.state.discount}
                            className="form-control-lg"
                            id="validationCustom04"
                            onChange={(e) =>
                              this.onChangeInput("discount", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Valid Start Date
                          </Label>
                          <Datetime
                            name="startDate"
                            value={moment(this.state.startDate)}
                            isValidDate={disablePastDt}
                            timeFormat={false}
                            onChange={(e) => {
                              this.onChangeInput(
                                "startDate",
                                e.format("YYYY-MM-DD")
                              );
                            }}
                            dateFormat={"DD/MM/YYYY"}
                          />
                           {errors?.startDate && (
                            <div className="error">{errors?.startDate} </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Valid End Date
                          </Label>

                          <Datetime
                            name="endDate"
                            value={moment(this.state.endDate)}
                            isValidDate={disablePastDt}
                            onChange={(e) => {
                              this.onChangeInput(
                                "endDate",
                                e.format("YYYY-MM-DD")
                              );
                            }}
                            dateFormat={"DD/MM/YYYY"}
                            timeFormat={false}
                          />
                            {errors?.endDate && (
                            <div className="error">{errors?.endDate} </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      color="primary"
                      // type="submit"
                      className="mt-4"
                      onClick={this.handleSubmit}
                    >
                      Update
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default connect(null, { brandList, updateCoupon, logoutUserSuccess })(
  UpdateCoupon
);
