import React, { Component } from "react";
import { MDBModal, MDBModalBody, MDBContainer, Card } from "mdbreact";
import CloseIcon from "@mui/icons-material/Close";

export default class BlogPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImg: "",
      content:"",
      title:"",
      subTitle:"",
      createdBy:"",
      createdAt:""
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.previewData !== this.props?.previewData) {
      let data = this.props?.previewData;
      if (this.props?.previewData) {
        this.setState({
          selectedImg: data?.images[0].imageData,
          createdBy:data?.createdAt?data?.createdAt:"N/A",
          createdAt:data?.createdBy?data?.createdBy:"N/A",
          content: data?.body ? data?.body :"N/A",
          subTitle: data?.subTitle ? data?.subTitle :"N/A",
          title: data?.title,
        });
      }
    }
  };
  render() {
    const modalStyle = {
      height: "100%",
      width: "100%",
      backgroundColor: "#ffffff",
      paddingTop: "2px",
      paddingLeft: "2px",
      paddingRight: "2px",
      paddingBottom: "auto",
      position: "relative",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      color: "black",
      width: "20px",
      marginRight: "0px",
    };
    return (
      <React.Fragment>
        <MDBContainer className="text-capitalize blogModel">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggleModel()}
            size="md"
            backdrop={true}
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          >
            <MDBModalBody>
              <Card style={modalStyle}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={headerStyle}>Preview</span>

                  <CloseIcon
                    style={closeStyle}
                    onClick={() => this.props.toggleModel()}
                  />
                </div>

                <div className="mt-0 p-0  product-img">
                  <img
                    src={this.state.selectedImg}
                    alt=""
                    className="img-fluid mx-auto d-block tab-img rounded"
                  ></img>
                </div>

                <div class="card-info">
                  <span><h2>{this.state.title}</h2></span>
                  <span class="card-title mt-2">
                    <p>{this.state.subTitle}</p>
                  </span>
                  <hr></hr>
                  <div class="card-about">
                    <span class="card-creator">{this.state.createdAt}</span>
                    <div class="card-time">{this.state.createdBy}</div>
                  </div>
                  <hr></hr>
                  <div>
                    <p>
                     {this.state.content}
                    </p>
                  </div>
                </div>
              </Card>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}
