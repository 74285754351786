import React, { Component } from "react";
import {
  updateBlog,
  getBlogCategories,
  fetchTag,
  uploadBlogImage,
} from "../../store/blogs/actions";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import { logoutUserSuccess } from "../../store/auth/actions";
import { MoonLoader } from "react-spinners";
//Dropzone
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
// import jwt_decode from "jwt-decode";
import { MDBModal, MDBModalBody, MDBContainer } from "mdbreact";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
} from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
// var tokenAuth = JSON.parse(window.localStorage.getItem("token"))
// ? JSON.parse(window.localStorage.getItem("token"))
// : " ";
class EditBlogModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogIds: [],
      id: "",
      content: "",
      images: [],
      loading: false,
      selectedImg: "",
      rootCategory: "",
      selectedGroup: "",
      selectedTag: "",
      optionTag: [],
      title: " ",
      subTitle: "",
      optionSlug: [],
      selectedFiles: [],
      selectedSlug: {},
    };

    this.myRef = React.createRef(null);
  }

  onChangeInput = (name, val) => {
    this.setState(
      {
        [`${name}`]: val,
      },
      () => {}
    );
  };
  optionGroup = (res) => {
    let optionGroupArr = [];
    let data = res?.response?.body ? res?.response?.body : [];
    if (data && data.length > 0) {
      data.map((slugOption) => {
        optionGroupArr.push({
          label: slugOption?.slugName,
          value: slugOption?.slugId,
        });
        return true;
      });
    }
    this.setState({ optionSlug: optionGroupArr });
  };

  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );
    this.setState({ selectedFiles: files }, () => {
      if (this.state.selectedFiles.length) {
        let imgArr = [];
        let img = this.state.selectedFiles?.map((res) => {
          return imgArr.push(res?.name);
        });
        console.log(img);
        this.setState({ images: imgArr });
        var formdata = new FormData();
        formdata.append(
          "file",
          this.state.selectedFiles[0],
          this.state.selectedFiles[0].name
        );

        this.setState({ loading: true });
        this.props.uploadBlogImage(formdata, (result) => {
          NotificationManager.success("Image upload Success", "Success");
          this.setState({ loading: false ,selectedImg: result });
        });
      }
    });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  removeFileselection = (file, i) => {
    this.setState({ selectedFiles: [] });
  };
  //Select
  handleSelectGroup = (selectedGroup) => {
    this.setState({ selectedGroup }, () => {});
  };
  handleTag = (selectedTag) => {
    this.setState({ selectedTag }, () => {});
  };

  handleSelectBlogCatGroup = (selectBlogCayegory) => {
    this.setState({ rootCategory: selectBlogCayegory }, () => {
      if (this.state.rootCategory) {
        let cat = this.state?.rootCategory?.value;
        this.props.getBlogCategories(cat, (res) => {
          console.log(res, "products");
          this.optionGroup(res);
        });
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.rowsData !== this.props?.rowsData) {
      let slugsList = this.props?.rowsData;
      const container = {};
      console.log(slugsList?.images[0].imageData);
      if (this.props?.rowsData) {
        let cat = slugsList?.rootCategory;
        this.props.getBlogCategories(cat, (res) => {
          console.log(res, "products");

          this.optionGroup(res);
        });
        const selectSlug = slugsList?.slug.map((item) => {
          container.label = item.slugName;
          container.value = item.slugId;
          this.setState({ selectedSlug: container });
          return container;
        });
        console.log(selectSlug);
        this.props.fetchTag((res) => {
          if (res?.response?.body?.length > 0) {
            let tagArr = [];
            let data = res?.response?.body;
            data &&
              data.map((res) => {
                return tagArr.push({ label: res?.tag, value: res?.tag });
              });
            this.setState({ optionTag: tagArr });
          }
        });
      }
      this.setState({
        selectedImg: slugsList?.images[0].imageData,
        blogIds: slugsList?.id,
        content: slugsList?.body ? slugsList?.body : "",
        subTitle: slugsList?.subTitle ? slugsList?.subTitle : "",
        id: slugsList?.id,
        rootCategory: slugsList?.rootCategory
          ? { label: slugsList?.rootCategory, value: slugsList?.rootCategory }
          : "",
        selectedTag: slugsList?.tags?.length
          ? { label: slugsList?.tags[0].tag, value: slugsList?.tags[0].tag }
          : { label: "", value: "" },
        title: slugsList?.title,
      });
    }
  };
  handleSubmit = () => {
    var tokenAuth = JSON.parse(window.localStorage.getItem("token"))
      ? JSON.parse(window.localStorage.getItem("token"))
      : " ";
    const {
      // blogIds,
      id,
      rootCategory,
      title,
      content,
      selectedTag,
      selectedGroup,
      selectedSlug,
      subTitle,
    } = this.state;
    let formObj = {
      // blogIds: [blogIds],
      id: id,
      title: title,
      subTitle: subTitle ? subTitle : "",
      body: content,
      rootCategory: rootCategory ? rootCategory?.value : "",
      slug: [
        {
          category: rootCategory?.label,
          slugName: selectedGroup ? selectedGroup?.label : selectedSlug.label,
        },
      ],
      images: [this.state.selectedImg],
      userAcessToken: tokenAuth,
      tags: [{ tag: selectedTag?.value }],
      blogStatus: "PUBLISHED",
    };

    this.props.updateBlog(formObj, (res) => {
      if (res?.code === 200) {
        NotificationManager.success("Published Successfully", "Success");
        this.props.closeModel();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  closeEditBlogModel = () => {
    this.setState({ selectedSlug: {} }, () => {
      this.props.closeModel();
    });
  };

  handleArchieve = () => {
    var tokenAuth = JSON.parse(window.localStorage.getItem("token"));
    const { blogIds, id } = this.state;
    let formObj = {
      blogIds: [blogIds],
      id: id,
      userAcessToken: tokenAuth,
      blogStatus: "ARCHIVED",
    };

    this.props.updateBlog(formObj, (res) => {
      if (res?.code === 200) {
        NotificationManager.success("Archived Succesfully", "Success");
        this.props.closeModel();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  render() {
    const blogCategoryOption = [
      { label: "Style", value: "Style" },
      { label: "Grooming", value: "Grooming" },
      { label: "Lifestyle", value: "Lifestyle" },
    ];
    const modalStyle = {
      height: "100%",
      maxHeight: "100%",
      width: "100%",
      backgroundColor: "#ffffff",
      paddingTop: "2px",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "auto",
      position: "relative",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      color: "#2800fc",
      width: "20px",
      marginRight: "0px",
    };
    const {
      selectedGroup,
      rootCategory,
      selectedSlug,
      optionTag,
      selectedTag,
      loading,
    } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#ff00ff"} />
          </Col>
        </div>
      );
    }
    return (
      <React.Fragment>
        <MDBContainer className="text-capitalize viewModel">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggleModel()}
            size="xl"
            backdrop={true}
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          >
            <MDBModalBody>
              <Card style={modalStyle}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={headerStyle}>Edit Blog</span>

                  <CloseIcon
                    style={closeStyle}
                    onClick={() => this.closeEditBlogModel()}
                  />
                </div>
                <div>
                  <Form>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Title
                          </Label>
                          <Input
                            name="title"
                            placeholder="Title"
                            type="text"
                            value={this.state.title}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("title", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Sub Title
                          </Label>
                          <Input
                            name="subTitle"
                            placeholder="Sub Title"
                            type="text"
                            value={this.state.subTitle}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("subTitle", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Content
                          </Label>
                          <Input
                            name="content"
                            placeholder="Content"
                            type="textarea"
                            row={3}
                            value={this.state.content}
                            className="form-control-lg"
                            id="validationCustom01"
                            onChange={(e) =>
                              this.onChangeInput("content", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="4">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Blog Category
                          </Label>
                          <Select
                            className="form-control-xs"
                            onChange={this.handleSelectBlogCatGroup}
                            value={rootCategory}
                            options={blogCategoryOption}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <FormGroup className="select2-container">
                          <Label className="control-label fontRoboto127">
                            Slug Select
                          </Label>
                          <Select
                            value={selectedGroup ? selectedGroup : selectedSlug}
                            isMulti={false}
                            onChange={this.handleSelectGroup}
                            options={this.state.optionSlug}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto127 mb-2"
                          >
                            Tag
                          </Label>
                          <Select
                            className="form-control-xs"
                            onChange={this.handleTag}
                            value={selectedTag}
                            options={optionTag}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="12">
                        <p className="card-title-desc">Upload Blog image</p>
                        <Form>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              this.handleAcceptedFiles(acceptedFiles)
                            }
                            accept="image/png, image/jpeg"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>                                 
                                  <img
                                    data-dz-thumbnail=""
                                    height="90"
                                    className="avatar-sm rounded bg-light"
                                    alt=""
                                    src={this.state.selectedImg}
                                  />
                                   <em>
                                    (Only *.jpeg and *.png images will be
                                    accepted)
                                  </em>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {this.state.selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={
                                            f.preview
                                              ? f.preview
                                              : this.state.selectedImg
                                          }
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                        <div style={{ flexGrow: "1" }}>
                                          <i
                                            className="fas fa-times"
                                            style={{
                                              cursor: "pointer",
                                              color: "red",
                                            }}
                                            onClick={() =>
                                              this.removeFileselection(f, i)
                                            }
                                          ></i>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </Form>
                      </Col>
                    </Row>

                    <Button
                      color="primary"
                      // type="submit"
                      className="mt-4 mr-4 mb-4"
                      onClick={this.handleSubmit}
                    >
                      PUBLISH
                    </Button>

                    <Button
                      color="danger"
                      // type="submit"
                      className="mt-4 mb-4"
                      onClick={this.handleArchieve}
                    >
                      ARCHIVE
                    </Button>
                  </Form>
                </div>
              </Card>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}
export default connect(null, {
  updateBlog,
  getBlogCategories,
  logoutUserSuccess,
  fetchTag,
  uploadBlogImage,
})(EditBlogModel);
